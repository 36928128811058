import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import classNames from 'classnames';
import { store } from 'Models/Store';
import If from '../Components/If/If';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface HubPageProps extends RouteComponentProps {}

const ProductPage = (props: HubPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content product-page">
				<div className="invisible-page-wrap centre centre-vertically limit-width">
					<div className="white-box">
						<div
							className="access-group-logo"
							role="img"
							aria-label="Access Intell - Customer Intelligence & Insight"
						/>
						<h5>Switch to</h5>
						<div className="product-boxes">
							<div className={classNames('product-box-wrap approve', {
								disabled: !store.canAccessApprove,
							})}
							>
								<a
									href={store.canAccessApprove
										? `${store.appSettings.approveBaseUrl}${store.appSettings.approveLoginPath}`
										: store.appSettings.approveMarketingUrl}
									target="_blank"
									rel="noreferrer"
								>
									<div className="product-box light-blue">
										<div className="icon-north-east icon-right" />
										<div
											className="product-box-logo approve"
											role="img"
											aria-label="Access Approve"
										/>
									</div>
									<p className="product-box-description">Applications</p>
								</a>
							</div>
							<div className={classNames('product-box-wrap', 'ppsr', {
								disabled: !store.canAccessPPSR,
							})}
							>
								<a
									href={store.canAccessPPSR
										? `${store.appSettings.ppsrBaseUrl}${store.appSettings.ppsrLoginPath}`
										: store.appSettings.ppsrMarketingUrl}
									target="_blank"
									rel="noreferrer"
								>
									<div className="product-box blue">
										<div className="icon-north-east icon-right" />
										<div
											className="product-box-logo ppsr"
											role="img"
											aria-label="Access PPSR"
										/>
									</div>
									<p className="product-box-description">Securities</p>
								</a>
							</div>
							<div className={classNames('product-box-wrap intel', {
								disabled: !store.canAccessIntel,
							})}
							>
								<a
									href={store.appSettings.monitorEnabled && store.canAccessIntel
										? '/monitor/dashboard'
										: store.appSettings.monitorMarketingUrl}
									target="_blank"
									rel="noreferrer"
								>
									<div className="product-box purple">
										<div className="icon-north-east icon-right" />
										<div
											className="product-box-logo intel"
											role="img"
											aria-label="Access Monitor"
										/>
										<If condition={!store.appSettings.monitorEnabled}>
											<p className="coming-soon-banner">Coming Soon!</p>
										</If>
									</div>
									<p className="product-box-description">Tracking</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};

export default ProductPage;
