import AwesomeDebouncePromise from 'awesome-debounce-promise';
import CustomerAuditEntity from 'Models/Entities/CustomerAuditEntity';
import { getApolloContext, gql } from '@apollo/client';
import { store } from 'Models/Store';
import { customerAuditCategory } from 'Models/Enums';
import { HasCondition, IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { buildSearchConditions } from 'Util/GraphQLUtils';
import { BusinessEntity, BusinessEntityUser, CustomerEntity } from 'Models/Entities';
import { isEqual } from 'lodash';
import moment from 'moment';

export interface CustomerAuditDescription {
	title: string;
	message?: string;
}

export interface CustomerAuditEntityResponse extends Omit<CustomerAuditEntity, 'description'> {
	description: CustomerAuditDescription;
}

export interface CustomerAuditFilterParams {
	category: customerAuditCategory | 'ALL';
	dateRange: Date[] | undefined;

}

export const CUSTOMER_AUDITS_PAGE_LENGTH = 10;

export const fetchCustomerAudits = AwesomeDebouncePromise(async (
	search: string,
	filterParams: CustomerAuditFilterParams,
	customerId: string,
	pageNo: number,
	getCount: (count: number) => void,
) : Promise<CustomerAuditEntityResponse[]> => {
	// Add search conditions
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	let searchConditions: IWhereCondition<string>[] = [];
	if (!!search) {
		searchConditions = buildSearchConditions(['description'], search, comparison);
	}

	const conditions: IWhereCondition<string>[][] = [];
	const conditionsOr: IWhereCondition<string>[][] = [];
	const hasConditions: HasCondition<CustomerAuditEntity>[][] = [];

	conditions.push([{
		comparison: 'equal', path: 'customerId', value: [customerId],
	}]);

	if (searchConditions.length > 0) {
		conditions.push(searchConditions);
	}

	// Add filtering conditions
	if (filterParams.category !== 'ALL') {
		conditions.push([{
			comparison: 'equal', path: 'category', value: filterParams.category,
		}]);
	}

	if (filterParams.dateRange && filterParams.dateRange.length === 2) {
		if (filterParams.dateRange[0].getDate() === filterParams.dateRange[1].getDate()) {
			conditions.push(
				[{ comparison: 'greaterThanOrEqual', path: 'created', value: filterParams.dateRange[0] }],
			);
		} else {
			// Add one day to the end date to make it inclusive
			filterParams.dateRange[1].setDate(filterParams.dateRange[1].getDate() + 1);
			conditions.push([{ comparison: 'between', path: 'created', value: filterParams.dateRange }]);
		}
	}

	// Add sorting
	const orderBy: {path: string, descending: boolean}[] = [];
	orderBy.push({ path: 'created', descending: true });

	const response = await store.apolloClient.query({
		query: gql`
			query getCustomerAuditEntitys(
				$orderBy: [OrderByGraph],
				$take: Int,
				$skip: Int,
				$conditions: [[WhereExpressionGraph]],
				$conditionsOr: [[WhereExpressionGraph]],
				$hasConditions: [[HasConditionType]],
			) {
				customerAuditEntitys(
						conditions: $conditions, 
						conditionsOr: $conditionsOr, 
						has: $hasConditions,
						take: $take
						skip: $skip
						orderBy: $orderBy
				) 
				{
					id
					${CustomerAuditEntity.getAttributes().join('\n')}
					user {
						id
						firstName
						lastName
					}
				}
				countCustomerAuditEntitys(
					conditions: $conditions,
					conditionsOr: $conditionsOr,
					has: $hasConditions,
				){
					number
				}
			}
		`,
		variables: {
			orderBy: orderBy,
			conditions: conditions,
			conditionsOr: conditionsOr,
			hasConditions: hasConditions,
			take: CUSTOMER_AUDITS_PAGE_LENGTH,
			skip: CUSTOMER_AUDITS_PAGE_LENGTH * pageNo,
		},
		fetchPolicy: 'network-only',
	});

	getCount(response.data.countCustomerAuditEntitys.number);

	const result = response.data.customerAuditEntitys as CustomerAuditEntity[];

	return result.map(item => {
		return {
			...item,
			description: JSON.parse(item.description) as CustomerAuditEntityResponse['description'],
		} as CustomerAuditEntityResponse;
	});
}, 500);

export const saveCustomerAudit = async (
	customerId: string,
	description: CustomerAuditDescription,
	category: customerAuditCategory,
) => {
	const customerAuditEntity = CreateCustomerAudit(customerId, category, description);
	await customerAuditEntity.save();
};

function AddChangedSection(
	changedSection: {section: string, previousValue: string, currentValue: string}[],
	section: string,
	previousValue: string,
	currentValue: string,
) {
	if (!isEqual(previousValue, currentValue)) {
		changedSection.push({
			section,
			previousValue,
			currentValue,
		});
	}
}

function getAddress(details: CustomerEntity, type: string) {
	const address = details[type];

	const response = [
		address?.line1,
		address?.line2,
		address?.suburb,
		address?.state,
		address?.postcode,
		address?.country,
	].filter(Boolean).join(' ');
	return response;
}

export function GetCustomerSingleAuditDescriptionFromString(
	previousValue: string,
	currentValue: string,
	section: string,
): CustomerAuditDescription {
	const parsedPreviousValue = previousValue && previousValue.length > 0 ? previousValue : 'N/A';
	const parsedCurrentValue = currentValue && currentValue.length > 0 ? currentValue : 'N/A';

	return {
		title: `${section} updated from ${parsedPreviousValue} to ${parsedCurrentValue}`,
		message: undefined,
	};
}

export function GetCustomerAuditsDescription(
	previousDetails: CustomerEntity,
	currentDetails: CustomerEntity,
	category: customerAuditCategory,
): CustomerAuditDescription | undefined {
	const changedSection: {section: string, previousValue: string, currentValue: string}[] = [];

	switch (category) {
		case 'ACCOUNT_INFORMATION': {
			AddChangedSection(changedSection, 'Credit limit', previousDetails.creditLimit, currentDetails.creditLimit);
			AddChangedSection(
				changedSection,
				'Payment terms',
				previousDetails.tradingTerms,
				currentDetails.tradingTerms,
			);
			AddChangedSection(
				changedSection,
				'Name for Invoice',
				previousDetails.nameForInvoice,
				currentDetails.nameForInvoice,
			);
			AddChangedSection(
				changedSection,
				'Accounts contact',
				previousDetails.accountsContact,
				currentDetails.accountsContact,
			);
			AddChangedSection(
				changedSection,
				'Accounts phone number',
				previousDetails.accountsPhoneNumber,
				currentDetails.accountsPhoneNumber,
			);
			AddChangedSection(
				changedSection,
				'Tax invoice email',
				previousDetails.taxInvoicesEmail,
				currentDetails.taxInvoicesEmail,
			);
			AddChangedSection(
				changedSection,
				'Statement email',
				previousDetails.statementsEmail,
				currentDetails.statementsEmail,
			);

			const previousPhysicalAddress = getAddress(previousDetails, 'physicalAddress');
			const currentPhysicalAddress = getAddress(currentDetails, 'physicalAddress');
			AddChangedSection(changedSection, 'Physical Address', previousPhysicalAddress, currentPhysicalAddress);

			const previousPostalAddress = getAddress(previousDetails, 'postalAddress');
			const currentPostalAddress = getAddress(currentDetails, 'postalAddress');
			AddChangedSection(changedSection, 'Postal address', previousPostalAddress, currentPostalAddress);
			break;
		}

		case 'BUSINESS_INFORMATION': {
			AddChangedSection(
				changedSection,
				'Business Name',
				previousDetails.businessName,
				currentDetails.businessName,
			);

			AddChangedSection(
				changedSection,
				'Debtor ID',
				previousDetails.debtorID,
				currentDetails.debtorID,
			);

			AddChangedSection(
				changedSection,
				"Customer's First Name",
				previousDetails.firstName,
				currentDetails.firstName,
			);

			AddChangedSection(
				changedSection,
				"Customer's Last Name",
				previousDetails.lastName,
				currentDetails.lastName,
			);

			const previousDateOfBirth = previousDetails.dateOfBirth
				? moment(previousDetails.dateOfBirth).format('DD/MM/YYYY')
				: '';
			const currentDateOfBirth = currentDetails.dateOfBirth
				? moment(currentDetails.dateOfBirth).format('DD/MM/YYYY')
				: '';
			AddChangedSection(
				changedSection,
				'Date of Birth',
				previousDateOfBirth,
				currentDateOfBirth,
			);

			const previousGroups = [...new Set(previousDetails.groupss.map(x => x.groups.name))].join(', ');
			const currentGroups = [...new Set(currentDetails.groupss.map(x => x.groups.name))].join(', ');
			AddChangedSection(
				changedSection,
				'Groups',
				previousGroups,
				currentGroups,
			);

			AddChangedSection(
				changedSection,
				'ABN',
				previousDetails.abn,
				currentDetails.abn,
			);

			AddChangedSection(
				changedSection,
				'ACN',
				previousDetails.acn,
				currentDetails.acn,
			);

			AddChangedSection(
				changedSection,
				'Trustee ACN',
				previousDetails.trusteeACN,
				currentDetails.trusteeACN,
			);

			AddChangedSection(
				changedSection,
				'Liquor Licence Number',
				previousDetails.drinksLicence,
				currentDetails.drinksLicence,
			);
			AddChangedSection(
				changedSection,
				'Liquor Licence State',
				previousDetails.drinksLicenceState,
				currentDetails.drinksLicenceState,
			);
			break;
		}
	}

	if (changedSection.length === 0) {
		return undefined;
	}

	if (changedSection.length === 1) {
		const { previousValue, currentValue } = changedSection[0];

		const parsedPreviousValue = previousValue && previousValue.length > 0 ? previousValue : 'N/A';
		const parsedCurrentValue = currentValue && currentValue.length > 0 ? currentValue : 'N/A';

		return {
			title: `${changedSection[0].section} updated from ${parsedPreviousValue} to ${parsedCurrentValue}`,
			message: undefined,
		};
	}

	return {
		title: 'Multiple Fields Updated',
		// eslint-disable-next-line max-len
		message: `The following fields have been updated:\n${GetMessageForCustomerAuditDescription(changedSection)}`,
	};
}

export function GetMessageForCustomerAuditDescription(
	array: {section: string, previousValue: string, currentValue: string}[],
): string | undefined {
	if (array.length === 0) {
		return undefined;
	}

	let result: string = '';

	array.forEach(item => {
		const parsedPreviousValue = item.previousValue && item.previousValue.length > 0 ? item.previousValue : 'N/A';
		const parsedCurrentValue = item.currentValue && item.currentValue.length > 0 ? item.currentValue : 'N/A';

		result = result.concat(`${item.section}\n  From ${parsedPreviousValue}\n  To ${parsedCurrentValue}\n\n`);
	});

	if (result.length > 0) {
		return result;
	}

	return undefined;
}

export function CreateCustomerAudit(
	customerId: string,
	category: customerAuditCategory,
	description: CustomerAuditDescription,
): CustomerAuditEntity {
	return new CustomerAuditEntity({
		customerId,
		category,
		description: JSON.stringify(description, (_, value) => {
			if (typeof value === 'undefined') {
				return null;
			}
			return value;
		}),
		userId: store.userId,
	});
}
