import { RouteComponentProps } from 'react-router-dom';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import If from 'Views/Components/If/If';
import { observer } from 'mobx-react';
import DashboardAgedTrialBalance from 'Views/Components/Intel/Dashboard/DashboardAgedTrialBalance';
import useStore from 'Hooks/useStore';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import DashboardAgedTrialBalanceNoData from 'Views/Components/Intel/Dashboard/DashboardAgedTrialBalanceNoData';
import React, { createContext, useEffect, useState } from 'react';
import type { DashboardGraphDataPoint } from 'Views/Components/Intel/Dashboard/DashboardAgedTrialBalanceGraph';
import { riskScore } from 'Models/Enums';
import OrganisationContextSwitcher from 'Views/Components/EntitySwitcher/OrganisationContextSwitcher';
import useDashboardData from 'Hooks/Api/useDashboardData';
import { requestBusiness } from 'Hooks/Api/requestBusiness';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { requestOrganisation } from 'Hooks/Api/requestOrganisation';
import DashboardBrownfieldProducts from 'Views/Components/Intel/Dashboard/DashboardBrownfieldProducts';
import { BusinessEntity } from 'Models/Entities';
import { SessionKeys } from 'Constants';
import MultiEntitySwitcher from 'Views/Components/EntitySwitcher/MultiEntitySwitcher';
import { DefaultCustomerListPageConfig } from './useCustomerListPageConfiguration/useCustomerListPageConfiguration';

async function fetchOrganisationOptions() {
	try {
		const response = await requestOrganisation({ filterByMonitor: true });
		const data = response.data.organisationEntitys;
		if (data === undefined) return [];
		const options = data.map(org => ({
			display: org.primaryBusinessEntity.name,
			value: org.id,
		} as ComboboxOption<string>));
		return options;
	} catch (e) {
		return [];
	}
}

async function fetchBusinessOptions(orgId: string) {
	try {
		const resposne = await requestBusiness({
			filterByMonitor: true,
			filterByOrgId: orgId,
		});
		const businesses = resposne.data.businessEntitys;
		if (businesses === undefined) return [];
		const options = businesses
			.filter(BusinessEntity.IsLinkedToUser)
			.map(business => ({
				display: business.name,
				value: business.id,
			} as ComboboxOption<string>));

		if (options.length > 1) {
			options.unshift({
				display: 'All',
				value: 'all',
			});
		}
		return options;
	} catch {
		return [];
	}
}

/**
 * @param value the selected business option which is a business or 'ALL'
 * @param businessOptions list of {display: string, value: string} options
 * @returns the matching list of business entity ids for the selected value.
 */
function mapBeOptionsToBeIds(value: string[] | undefined, businessOptions: ComboboxOption<string>[]): string[] {
	if (value?.includes('all')) return businessOptions.filter(x => x.value !== 'all').map(o => o.value ?? '');
	if (value) return value;
	return [];
}

export interface MonitorDashboardPageProps { }

export interface DashboardApiResponse {
	uniqueCustomersInBuckets: { [key in riskScore]: {
		count: number,
		amount: number,
	} },
	customersWithAdverseAlerts: number,
	customersAtRisk: number,
	latestUploadTime: string,
	graphData: DashboardGraphDataPoint[],
}

export const AtbDashboardContext = createContext<DashboardApiResponse>({} as DashboardApiResponse);

const MonitorDashboardPage = observer((props: RouteComponentProps<MonitorDashboardPageProps>) => {
	const store = useStore();
	const orgId = store.impersonatingOrganisationId;

	const [hasAllSelected, sethasAllSelected] = useState(false);
	const [businessIds, setBusinessIds] = useState<string[] | undefined>(undefined);

	// businessOptions has undefined type so that we can handle loading states properly for super user
	const [businessOptions, setBusinessOptions] = useState<ComboboxOption<string>[] | undefined>(undefined);
	const [orgOptions, setOrgOptions] = useState<ComboboxOption<string>[]>([]);

	const [reqDashboardOptions, setReqDashboardOptions] = useState<{
		organisationId: string, businessIds: string[]
	}>({
		organisationId: orgId,
		businessIds: [],
	});

	useEffect(() => {
		// Reset customer list page filters back to normal
		sessionStorage.removeItem(SessionKeys.customerListPageConfig);
	}, []);

	useEffect(() => {
		// Update request arguments for new dashboard data when user selects new business
		setReqDashboardOptions({
			organisationId: orgId,
			businessIds: mapBeOptionsToBeIds(businessIds, businessOptions ?? []),
		});
	}, [businessIds, orgId, businessOptions]);

	const reqData = useDashboardData(reqDashboardOptions);

	useEffect(() => {
		async function asyncwrapper() {
			setOrgOptions(await fetchOrganisationOptions());
		}
		// Only fetch for super user's who are able to impersonate organisations
		if (store.userType === 'SUPER_USER') asyncwrapper();
	}, [store.userType]);

	useEffect(() => {
		// Reset user selection when super impersonates new organisation
		setBusinessIds(undefined);
		setBusinessOptions(undefined);
		setReqDashboardOptions({
			organisationId: orgId,
			businessIds: [],
		});

		// Fetch the business entities and set the default selected value and the available selectable options
		async function asyncwrapper() {
			const _businessOptions = await fetchBusinessOptions(orgId);
			setBusinessOptions(_businessOptions);
			if (_businessOptions.length > 0 && _businessOptions[0].value !== undefined) setBusinessIds([_businessOptions[0].value]);
		}
		asyncwrapper();
	}, [orgId]);

	const showOrgSwitcher = store.userType === 'SUPER_USER';

	async function getOrgOptionWrapper(searchTerm?: string): Promise<ComboboxOption<string>[]> {
		if (!searchTerm) return orgOptions;
		return orgOptions.filter(o => o.display?.toString().toLowerCase().includes(searchTerm.toLowerCase()));
	}

	function getBusinessOptionWrapper(searchTerm?: string): ComboboxOption<string>[] {
		if (!searchTerm) return (businessOptions ?? []);
		return (businessOptions ?? []).filter(o => o.display?.toString().toLowerCase().includes(searchTerm.toLowerCase()));
	}

	if (reqData.type !== 'data' || businessOptions === undefined) return <EmptyPage {...props} />;

	function handleSetBusiness(value: string[] | undefined) {
		// Always have something selected if there is data.
		if (value !== undefined && value.length === 0) return;

		if (value?.includes('all') && !hasAllSelected) {
			sethasAllSelected(true);
			setBusinessIds(['all']);
			return;
		}

		const updatedValue = value?.filter(x => x !== 'all');
		if (hasAllSelected) sethasAllSelected(false);
		setBusinessIds(updatedValue);
	}

	function handleAfterSetBusiness() {
		// Set the initial beid filters for customer list page
		sessionStorage.setItem(SessionKeys.customerListPageConfig, JSON.stringify({
			sort: DefaultCustomerListPageConfig.sort,
			filter: {
				...DefaultCustomerListPageConfig.filter,
				businessEntityIds: (businessIds ?? []).filter(x => x !== 'all').map(x => x),
			},
		}));
	}

	return (
		<AccessIntelSecuredPage routeComponentProps={props} product="monitor">
			<div className="body-content monitor-dashboard-page" key={orgId}>
				<If condition={reqData.type === 'data'}>
					<AtbDashboardContext.Provider value={reqData.data ?? {} as DashboardApiResponse}>
						<div className="invisible-page-wrap">
							<div className="top-container" style={{ justifyContent: 'start' }}>
								<PageBreadcrumbs tags={[{ label: 'Dashboard' }]} />
								<div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
									{showOrgSwitcher && (
										<OrganisationContextSwitcher
											value={orgId}
											setValue={id => { if (id) store.impersonatingOrganisationId = id; }}
											getOptions={searchTerm => getOrgOptionWrapper(searchTerm)}
										/>
									)}
									<MultiEntitySwitcher
										objectName="business"
										value={businessIds}
										setValue={v => handleSetBusiness(v)}
										handleAfterSet={() => handleAfterSetBusiness()}
										getOptions={searchTerm => getBusinessOptionWrapper(searchTerm)}
										tooltipContent="Filter your dashboard data by the selected business"
									/>
								</div>
							</div>

							<div className="flex-col">
								{!!reqData.data?.latestUploadTime
									? (<DashboardAgedTrialBalance beIds={mapBeOptionsToBeIds(businessIds, businessOptions ?? [])} />)
									: (<DashboardAgedTrialBalanceNoData />)}

								<DashboardBrownfieldProducts />
							</div>
						</div>
					</AtbDashboardContext.Provider>
				</If>
			</div>
		</AccessIntelSecuredPage>
	);
});

export default MonitorDashboardPage;

function EmptyPage(props: RouteComponentProps<MonitorDashboardPageProps>) {
	return (
		<AccessIntelSecuredPage routeComponentProps={props} product="monitor">
			<div className="body-content monitor-dashboard-page">
				<InlineSpinner />
			</div>
		</AccessIntelSecuredPage>
	);
}
