import * as React from 'react';
import { CustomerEntity } from 'Models/Entities';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import classNames from 'classnames';
import { useMemo } from 'react';
import If from 'Views/Components/If/If';
import HandleEvents from 'Util/HandleEvents';

interface AdverseAlertsSummary {
	courtCases: number,
	adjudications: number,
	insolvencies: number,
	licenses: number,
	documents: number,
}
const CustomerOverviewAdverseAlerts = (props: {
	customer: CustomerEntity,
	onAdverseAlertsClick: () => void,
}) => {
	const { customer, onAdverseAlertsClick } = props;

	const adverseAlertsSummary = useAsync<AdverseAlertsSummary>(async () => {
		const { data } = await axios.get(`${SERVER_URL}/api/entity/AlaresEntity/customer-summary/${customer.id}`);
		return data as AdverseAlertsSummary;
	}, []);
	const otherCount = useMemo(() => {
		return (adverseAlertsSummary.data?.adjudications ?? 0) + (adverseAlertsSummary.data?.insolvencies ?? 0)
			+ (adverseAlertsSummary.data?.licenses ?? 0);
	}, [adverseAlertsSummary]);
	const responseCompleted = useMemo<boolean>(() => adverseAlertsSummary.type === 'data', [adverseAlertsSummary]);
	const totalCount = useMemo<number>(() => {
		if (adverseAlertsSummary.type === 'data') {
			let sum = 0;
			['courtCases', 'adjudications', 'insolvencies', 'licenses'].forEach(field => {
				sum += adverseAlertsSummary.data[field] ?? 0;
			});
			return sum;
		}
		return customer.newestAlares?.adverseAlerts ?? 0;
	}, [adverseAlertsSummary, customer.newestAlares]);

	function renderSummary(summaryName: string, completed: boolean, count?: number) {
		const adjustedCount = completed ? count ?? 0 : '-';
		return (
			<div>
				<p className="field-name">{summaryName}</p>
				<p className={classNames('count', {
					highlight: adjustedCount > 0,
				})}
				>
					{adjustedCount}
				</p>
			</div>
		);
	}

	return (
		<div className="overview-adverse-alerts" {...HandleEvents(onAdverseAlertsClick)}>
			<div className="header">
				<h6>Adverse Alerts</h6>
				<If condition={totalCount > 0}>
					<div className="adverse-alerts-icon">
						<span className="icon icon-only icon-material-error" />
					</div>
				</If>
			</div>
			<div className="counts">
				{renderSummary('Court Cases', responseCompleted, adverseAlertsSummary.data?.courtCases)}
				{renderSummary('ASIC Updates', responseCompleted, adverseAlertsSummary.data?.documents)}
				{renderSummary('Other', responseCompleted, otherCount)}
			</div>
		</div>
	);
};

export default CustomerOverviewAdverseAlerts;
