import CustomerAuditsTable from './CustomerAuditsTable';
import { CustomerEntity } from 'Models/Entities';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import React from 'react';
import { CustomerAuditFilterParams, fetchCustomerAudits } from './Gibs/FetchCustomerAudits';
import { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import CustomerAuditsSearchAndFilterSection from './CustomerAuditsSearchAndFilterSection';
import CustomerAuditDescriptionModal from './Gibs/CustomerAuditDescriptionModal';

interface Props {
	customer: CustomerEntity;
}

const CustomerAuditsTab: React.FunctionComponent<Props> = ({ customer }) => {
	const [showCommentMessageModal, setShowCommentMessageModal] = React.useState(false);
	const [updateList, setUpdateList] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [search, setSearch] = React.useState('');
	const [auditFilterParams, setAuditFilterParams] = React.useState<CustomerAuditFilterParams>(() => (
		{
			category: 'ALL',
			dateRange: undefined,
		}
	));

	const refreshKey = React.useMemo(() => (GetRefreshKey(
		search,
		...Object.values(auditFilterParams),
		page,
		updateList,
	)), [auditFilterParams, page, search, updateList]);

	const fetchCustomerAudit = React.useCallback(() => (
		fetchCustomerAudits(
			search,
			auditFilterParams,
			customer.id,
			page,
			setCount,
		)), [auditFilterParams, customer.id, page, search]);

	return (
		<div className="customer-audits">
			<div className="bread-crumbs">
				<div className="customer-audits-comment">
					<Button
						className="add-comment"
						display={Display.Solid}
						icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						onClick={() => {
							setShowCommentMessageModal(true);
						}}
					>
						Add Comment
					</Button>
				</div>
				<CustomerAuditsSearchAndFilterSection
					search={search}
					auditFilterParams={auditFilterParams}
					resetPage={() => setPage(0)}
					setAuditFilterParams={setAuditFilterParams}
					setSearch={setSearch}
				/>
			</div>
			<CustomerAuditDescriptionModal
				initialDescription={{ title: '' }}
				customerId={customer.id}
				openModal={showCommentMessageModal}
				closeModal={() => setShowCommentMessageModal(false)}
				category="COMMENT"
				refreshList={() => setUpdateList(prevState => !prevState)}
				isReadOnly={false}
			/>
			<CustomerAuditsTable
				page={page}
				count={count}
				refreshKey={refreshKey}
				setPage={setPage}
				fetchCustomerAudits={fetchCustomerAudit}
			/>
		</div>
	);
};
export default CustomerAuditsTab;
