import { SessionKeys } from 'Constants';
import * as H from 'history';
import { ausStates, riskScore, riskScoreOptions } from 'Models/Enums';
import * as queryString from 'querystring';
import { FilterParams, SortParams } from 'Views/Components/Intel/Customer/CustomerList';

export type CustomerListPageSearchParams = {
	/**
	 * Search fields
	 */
	sort?: string; // Name of the field to sort by
	dir?: string;

	/**
	 * Filter fields
	 */
	auState?: ausStates | 'ALL';
	businessEntityIds?: string[];
	paymentRiskRating?: riskScore | 'ALL';
	insolvencyRiskRating?: riskScore | 'ALL';
	riskBucket?: (riskScore | 'ALL')[];
	groupIds?: string[];
}
const CustomerListPageSearchParamsKeys: (keyof CustomerListPageSearchParams)[] = [
	'sort', 'dir', 'auState', 'businessEntityIds', 'paymentRiskRating', 'insolvencyRiskRating', 'riskBucket', 'groupIds',
];

export const riskScoreList = Object.keys(riskScoreOptions) as (riskScore | 'ALL')[];

function sanitizeRiskScoreValues(values: (riskScore | 'ALL')[]) {
	return values.filter(v => riskScoreList.includes(v));
}

export type CustomerListPageConfig = {
	sort: SortParams;
	filter: FilterParams;
}

export const DefaultCustomerListPageConfig: CustomerListPageConfig = {
	sort: {
		fieldName: 'businessName',
		direction: 'ASC',
	},
	filter: {
		auState: 'ALL',
		businessEntityIds: [],
		insolvencyRiskRating: 'ALL',
		paymentRiskRating: 'ALL',
		riskBucket: ['ALL'],
		groupIds: [],
	},
};

/**
 * Build the customer list page configuration which can be sourced from 3 areas.
 * 1. query params - this overrides everything
 * 2. session storage
 * 3. default values
 */
export function useCustomerListPageConfiguration(location: H.Location): CustomerListPageConfig {
	const searchParamUrl = location.search ? location.search.substring(1) : '';
	const sessionConfigString = sessionStorage.getItem(SessionKeys.customerListPageConfig);
	const queryParams = new URLSearchParams(searchParamUrl);

	// 1. If there is a valid query param, load page config from it
	if (CustomerListPageSearchParamsKeys.some(key => queryParams.has(key))) {
		return createConfigFromQuerySearchParams(searchParamUrl);
	}

	// 2. if there is a valid session config, load page config from it
	if (sessionConfigString != null) {
		try {
			return JSON.parse(sessionConfigString) as CustomerListPageConfig;
		} catch {
			console.error(`Error parsing ${sessionConfigString}. Clearing value from session to prevent recurring issue.`);
			sessionStorage.removeItem(SessionKeys.customerListPageConfig);
		}
	}
	// 3. No config, so we just stick with the default
	// Deep clone so we don't mutate the default values
	return JSON.parse(JSON.stringify(DefaultCustomerListPageConfig));
}

// Example format for the single and array params
// http://localhost:5000/monitor/customers?sort=abn&dir=DESC
// http://localhost:5000/monitor/customers?riskBucket=UNKNOWN&riskBucket=LOW
function createConfigFromQuerySearchParams(searchParamUrl: string) {
	const {
		sort,
		dir,
		auState,
		businessEntityIds,
		paymentRiskRating,
		insolvencyRiskRating,
		riskBucket,
		groupIds,
	}: CustomerListPageSearchParams = queryString.parse(searchParamUrl);
	// Deep clone so we don't mutate the default values
	const queryParamsConfig: CustomerListPageConfig = JSON.parse(JSON.stringify(DefaultCustomerListPageConfig));

	// 1. Process query params for sorting
	if (sort) {
		queryParamsConfig.sort.fieldName = sort;
	}
	if (dir) {
		queryParamsConfig.sort.direction = dir as 'ASC' | 'DESC' ?? 'ASC';
	}
	// 2. Process query params for filtering
	if (auState) {
		queryParamsConfig.filter.auState = auState;
	}
	if (businessEntityIds) {
		queryParamsConfig.filter.businessEntityIds = Array.isArray(businessEntityIds) ? businessEntityIds : [businessEntityIds];
	}
	if (paymentRiskRating) {
		queryParamsConfig.filter.paymentRiskRating = paymentRiskRating;
	}
	if (insolvencyRiskRating) {
		queryParamsConfig.filter.insolvencyRiskRating = insolvencyRiskRating;
	}
	if (riskBucket) {
		const rawRiskBucketValues = Array.isArray(riskBucket) ? riskBucket : [riskBucket];
		queryParamsConfig.filter.riskBucket = sanitizeRiskScoreValues(rawRiskBucketValues);
	}
	if (groupIds) {
		queryParamsConfig.filter.groupIds = Array.isArray(groupIds) ? groupIds : [groupIds];
	}
	return queryParamsConfig;
}
