/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from 'Views/Components/Helpers/Common';
import SecurityService from 'Services/SecurityService';
import { getModelDisplayName } from 'Util/EntityUtils';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
	// % protected region % [Add extra AdminLink fields here] off begin
	// % protected region % [Add extra AdminLink fields here] end
}

const getPageLinks = (): AdminLink[] => [
	{
		// % protected region % [Override navigation link for ActiveCustomerPricingTierEntity here] off begin
		path: '/admin/activecustomerpricingtierentity',
		label: getModelDisplayName(Models.ActiveCustomerPricingTierEntity),
		entity: Models.ActiveCustomerPricingTierEntity,
		isMember: false,
		// % protected region % [Override navigation link for ActiveCustomerPricingTierEntity here] end
	},
	{
		// % protected region % [Override navigation link for ActiveUserPricingTierEntity here] off begin
		path: '/admin/activeuserpricingtierentity',
		label: getModelDisplayName(Models.ActiveUserPricingTierEntity),
		entity: Models.ActiveUserPricingTierEntity,
		isMember: false,
		// % protected region % [Override navigation link for ActiveUserPricingTierEntity here] end
	},
	{
		// % protected region % [Override navigation link for AddressEntity here] off begin
		path: '/admin/addressentity',
		label: getModelDisplayName(Models.AddressEntity),
		entity: Models.AddressEntity,
		isMember: false,
		// % protected region % [Override navigation link for AddressEntity here] end
	},
	{
		// % protected region % [Override navigation link for AlaresCategoryRankingEntity here] off begin
		path: '/admin/alarescategoryrankingentity',
		label: getModelDisplayName(Models.AlaresCategoryRankingEntity),
		entity: Models.AlaresCategoryRankingEntity,
		isMember: false,
		// % protected region % [Override navigation link for AlaresCategoryRankingEntity here] end
	},
	{
		// % protected region % [Override navigation link for AlaresEntity here] off begin
		path: '/admin/alaresentity',
		label: getModelDisplayName(Models.AlaresEntity),
		entity: Models.AlaresEntity,
		isMember: false,
		// % protected region % [Override navigation link for AlaresEntity here] end
	},
	{
		// % protected region % [Override navigation link for ApplicationEntity here] off begin
		path: '/admin/applicationentity',
		label: getModelDisplayName(Models.ApplicationEntity),
		entity: Models.ApplicationEntity,
		isMember: false,
		// % protected region % [Override navigation link for ApplicationEntity here] end
	},
	{
		// % protected region % [Override navigation link for AtbFileEntity here] off begin
		path: '/admin/atbfileentity',
		label: getModelDisplayName(Models.AtbFileEntity),
		entity: Models.AtbFileEntity,
		isMember: false,
		// % protected region % [Override navigation link for AtbFileEntity here] end
	},
	{
		// % protected region % [Override navigation link for AtbRecordEntity here] off begin
		path: '/admin/atbrecordentity',
		label: getModelDisplayName(Models.AtbRecordEntity),
		entity: Models.AtbRecordEntity,
		isMember: false,
		// % protected region % [Override navigation link for AtbRecordEntity here] end
	},
	{
		// % protected region % [Override navigation link for AtoDebtEntity here] off begin
		path: '/admin/atodebtentity',
		label: getModelDisplayName(Models.AtoDebtEntity),
		entity: Models.AtoDebtEntity,
		isMember: false,
		// % protected region % [Override navigation link for AtoDebtEntity here] end
	},
	{
		// % protected region % [Override navigation link for AtoFileEntity here] off begin
		path: '/admin/atofileentity',
		label: getModelDisplayName(Models.AtoFileEntity),
		entity: Models.AtoFileEntity,
		isMember: false,
		// % protected region % [Override navigation link for AtoFileEntity here] end
	},
	{
		// % protected region % [Override navigation link for AtoRecordEntity here] off begin
		path: '/admin/atorecordentity',
		label: getModelDisplayName(Models.AtoRecordEntity),
		entity: Models.AtoRecordEntity,
		isMember: false,
		// % protected region % [Override navigation link for AtoRecordEntity here] end
	},
	{
		// % protected region % [Override navigation link for BusinessEntity here] off begin
		path: '/admin/businessentity',
		label: getModelDisplayName(Models.BusinessEntity),
		entity: Models.BusinessEntity,
		isMember: false,
		// % protected region % [Override navigation link for BusinessEntity here] end
	},
	{
		// % protected region % [Override navigation link for CreditBureauEntity here] off begin
		path: '/admin/creditbureauentity',
		label: getModelDisplayName(Models.CreditBureauEntity),
		entity: Models.CreditBureauEntity,
		isMember: false,
		// % protected region % [Override navigation link for CreditBureauEntity here] end
	},
	{
		// % protected region % [Override navigation link for CreditReportEntity here] off begin
		path: '/admin/creditreportentity',
		label: getModelDisplayName(Models.CreditReportEntity),
		entity: Models.CreditReportEntity,
		isMember: false,
		// % protected region % [Override navigation link for CreditReportEntity here] end
	},
	{
		// % protected region % [Override navigation link for CustomerAuditEntity here] off begin
		path: '/admin/customerauditentity',
		label: getModelDisplayName(Models.CustomerAuditEntity),
		entity: Models.CustomerAuditEntity,
		isMember: false,
		// % protected region % [Override navigation link for CustomerAuditEntity here] end
	},
	{
		// % protected region % [Override navigation link for CustomerEntity here] off begin
		path: '/admin/customerentity',
		label: getModelDisplayName(Models.CustomerEntity),
		entity: Models.CustomerEntity,
		isMember: false,
		// % protected region % [Override navigation link for CustomerEntity here] end
	},
	{
		// % protected region % [Override navigation link for DiscountEntity here] off begin
		path: '/admin/discountentity',
		label: getModelDisplayName(Models.DiscountEntity),
		entity: Models.DiscountEntity,
		isMember: false,
		// % protected region % [Override navigation link for DiscountEntity here] end
	},
	{
		// % protected region % [Override navigation link for DocumentEntity here] off begin
		path: '/admin/documententity',
		label: getModelDisplayName(Models.DocumentEntity),
		entity: Models.DocumentEntity,
		isMember: false,
		// % protected region % [Override navigation link for DocumentEntity here] end
	},
	{
		// % protected region % [Override navigation link for EsignceremonysigneeEntity here] off begin
		path: '/admin/esignceremonysigneeentity',
		label: getModelDisplayName(Models.EsignceremonysigneeEntity),
		entity: Models.EsignceremonysigneeEntity,
		isMember: false,
		// % protected region % [Override navigation link for EsignceremonysigneeEntity here] end
	},
	{
		// % protected region % [Override navigation link for EsignceremonywitnessEntity here] off begin
		path: '/admin/esignceremonywitnessentity',
		label: getModelDisplayName(Models.EsignceremonywitnessEntity),
		entity: Models.EsignceremonywitnessEntity,
		isMember: false,
		// % protected region % [Override navigation link for EsignceremonywitnessEntity here] end
	},
	{
		// % protected region % [Override navigation link for EsigntemplateEntity here] off begin
		path: '/admin/esigntemplateentity',
		label: getModelDisplayName(Models.EsigntemplateEntity),
		entity: Models.EsigntemplateEntity,
		isMember: false,
		// % protected region % [Override navigation link for EsigntemplateEntity here] end
	},
	{
		// % protected region % [Override navigation link for EsigntemplatecontentEntity here] off begin
		path: '/admin/esigntemplatecontententity',
		label: getModelDisplayName(Models.EsigntemplatecontentEntity),
		entity: Models.EsigntemplatecontentEntity,
		isMember: false,
		// % protected region % [Override navigation link for EsigntemplatecontentEntity here] end
	},
	{
		// % protected region % [Override navigation link for EsignuserEntity here] off begin
		path: '/admin/esignuserentity',
		label: getModelDisplayName(Models.EsignuserEntity),
		entity: Models.EsignuserEntity,
		isMember: false,
		// % protected region % [Override navigation link for EsignuserEntity here] end
	},
	{
		// % protected region % [Override navigation link for EsignworkflowEntity here] off begin
		path: '/admin/esignworkflowentity',
		label: getModelDisplayName(Models.EsignworkflowEntity),
		entity: Models.EsignworkflowEntity,
		isMember: false,
		// % protected region % [Override navigation link for EsignworkflowEntity here] end
	},
	{
		// % protected region % [Override navigation link for GroupEntity here] off begin
		path: '/admin/groupentity',
		label: getModelDisplayName(Models.GroupEntity),
		entity: Models.GroupEntity,
		isMember: false,
		// % protected region % [Override navigation link for GroupEntity here] end
	},
	{
		// % protected region % [Override navigation link for IndustryCodesEntity here] off begin
		path: '/admin/industrycodesentity',
		label: getModelDisplayName(Models.IndustryCodesEntity),
		entity: Models.IndustryCodesEntity,
		isMember: false,
		// % protected region % [Override navigation link for IndustryCodesEntity here] end
	},
	{
		// % protected region % [Override navigation link for InvoiceEntity here] off begin
		path: '/admin/invoiceentity',
		label: getModelDisplayName(Models.InvoiceEntity),
		entity: Models.InvoiceEntity,
		isMember: false,
		// % protected region % [Override navigation link for InvoiceEntity here] end
	},
	{
		// % protected region % [Override navigation link for LogoEntity here] off begin
		path: '/admin/logoentity',
		label: getModelDisplayName(Models.LogoEntity),
		entity: Models.LogoEntity,
		isMember: false,
		// % protected region % [Override navigation link for LogoEntity here] end
	},
	{
		// % protected region % [Override navigation link for MonitoredEntity here] off begin
		path: '/admin/monitoredentity',
		label: getModelDisplayName(Models.MonitoredEntity),
		entity: Models.MonitoredEntity,
		isMember: false,
		// % protected region % [Override navigation link for MonitoredEntity here] end
	},
	{
		// % protected region % [Override navigation link for MonitoringProviderEntity here] off begin
		path: '/admin/monitoringproviderentity',
		label: getModelDisplayName(Models.MonitoringProviderEntity),
		entity: Models.MonitoringProviderEntity,
		isMember: false,
		// % protected region % [Override navigation link for MonitoringProviderEntity here] end
	},
	{
		// % protected region % [Override navigation link for MonthlyExposurePricingTierEntity here] off begin
		path: '/admin/monthlyexposurepricingtierentity',
		label: getModelDisplayName(Models.MonthlyExposurePricingTierEntity),
		entity: Models.MonthlyExposurePricingTierEntity,
		isMember: false,
		// % protected region % [Override navigation link for MonthlyExposurePricingTierEntity here] end
	},
	{
		// % protected region % [Override navigation link for OptionHowDidYouHearEntity here] off begin
		path: '/admin/optionhowdidyouhearentity',
		label: getModelDisplayName(Models.OptionHowDidYouHearEntity),
		entity: Models.OptionHowDidYouHearEntity,
		isMember: false,
		// % protected region % [Override navigation link for OptionHowDidYouHearEntity here] end
	},
	{
		// % protected region % [Override navigation link for OptionReferrerCharityEntity here] off begin
		path: '/admin/optionreferrercharityentity',
		label: getModelDisplayName(Models.OptionReferrerCharityEntity),
		entity: Models.OptionReferrerCharityEntity,
		isMember: false,
		// % protected region % [Override navigation link for OptionReferrerCharityEntity here] end
	},
	{
		// % protected region % [Override navigation link for OrganisationEntity here] off begin
		path: '/admin/organisationentity',
		label: getModelDisplayName(Models.OrganisationEntity),
		entity: Models.OrganisationEntity,
		isMember: false,
		// % protected region % [Override navigation link for OrganisationEntity here] end
	},
	{
		// % protected region % [Override navigation link for PaymentStatusEntity here] off begin
		path: '/admin/paymentstatusentity',
		label: getModelDisplayName(Models.PaymentStatusEntity),
		entity: Models.PaymentStatusEntity,
		isMember: false,
		// % protected region % [Override navigation link for PaymentStatusEntity here] end
	},
	{
		// % protected region % [Override navigation link for ProviderEntity here] off begin
		path: '/admin/providerentity',
		label: getModelDisplayName(Models.ProviderEntity),
		entity: Models.ProviderEntity,
		isMember: false,
		// % protected region % [Override navigation link for ProviderEntity here] end
	},
	{
		// % protected region % [Override navigation link for ProviderProductEntity here] off begin
		path: '/admin/providerproductentity',
		label: getModelDisplayName(Models.ProviderProductEntity),
		entity: Models.ProviderProductEntity,
		isMember: false,
		// % protected region % [Override navigation link for ProviderProductEntity here] end
	},
	{
		// % protected region % [Override navigation link for ProviderProductOrganisationEntity here] off begin
		path: '/admin/providerproductorganisationentity',
		label: getModelDisplayName(Models.ProviderProductOrganisationEntity),
		entity: Models.ProviderProductOrganisationEntity,
		isMember: false,
		// % protected region % [Override navigation link for ProviderProductOrganisationEntity here] end
	},
	{
		// % protected region % [Override navigation link for ProviderTypeEntity here] off begin
		path: '/admin/providertypeentity',
		label: getModelDisplayName(Models.ProviderTypeEntity),
		entity: Models.ProviderTypeEntity,
		isMember: false,
		// % protected region % [Override navigation link for ProviderTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for ReferralPartnerGroupEntity here] off begin
		path: '/admin/referralpartnergroupentity',
		label: getModelDisplayName(Models.ReferralPartnerGroupEntity),
		entity: Models.ReferralPartnerGroupEntity,
		isMember: false,
		// % protected region % [Override navigation link for ReferralPartnerGroupEntity here] end
	},
	{
		// % protected region % [Override navigation link for RegistrationEntity here] off begin
		path: '/admin/registrationentity',
		label: getModelDisplayName(Models.RegistrationEntity),
		entity: Models.RegistrationEntity,
		isMember: false,
		// % protected region % [Override navigation link for RegistrationEntity here] end
	},
	{
		// % protected region % [Override navigation link for RegistrationFileEntity here] off begin
		path: '/admin/registrationfileentity',
		label: getModelDisplayName(Models.RegistrationFileEntity),
		entity: Models.RegistrationFileEntity,
		isMember: false,
		// % protected region % [Override navigation link for RegistrationFileEntity here] end
	},
	{
		// % protected region % [Override navigation link for ReportRequestEntity here] off begin
		path: '/admin/reportrequestentity',
		label: getModelDisplayName(Models.ReportRequestEntity),
		entity: Models.ReportRequestEntity,
		isMember: false,
		// % protected region % [Override navigation link for ReportRequestEntity here] end
	},
	{
		// % protected region % [Override navigation link for SmsTokenEntity here] off begin
		path: '/admin/smstokenentity',
		label: getModelDisplayName(Models.SmsTokenEntity),
		entity: Models.SmsTokenEntity,
		isMember: false,
		// % protected region % [Override navigation link for SmsTokenEntity here] end
	},
	{
		// % protected region % [Override navigation link for SpgEntity here] off begin
		path: '/admin/spgentity',
		label: getModelDisplayName(Models.SpgEntity),
		entity: Models.SpgEntity,
		isMember: false,
		// % protected region % [Override navigation link for SpgEntity here] end
	},
	{
		// % protected region % [Override navigation link for TrustEntityCodesEntity here] off begin
		path: '/admin/trustentitycodesentity',
		label: getModelDisplayName(Models.TrustEntityCodesEntity),
		entity: Models.TrustEntityCodesEntity,
		isMember: false,
		// % protected region % [Override navigation link for TrustEntityCodesEntity here] end
	},
	{
		// % protected region % [Override navigation link for UserEntity here] off begin
		path: '/admin/userentity',
		label: getModelDisplayName(Models.UserEntity),
		entity: Models.UserEntity,
		isMember: true,
		// % protected region % [Override navigation link for UserEntity here] end
	},
	// % protected region % [Add any extra page links here] off begin
	// % protected region % [Add any extra page links here] end
];

export default class PageLinks extends React.Component<RouteComponentProps> {
	private filter = (link: AdminLink) => {
		return SecurityService.canRead(link.entity);
	}

	public render() {
		return (
			<Navigation
				className="nav__admin"
				orientation={Orientation.VERTICAL}
				linkGroups={this.getAdminNavLinks()}
				{...this.props}
			/>
		);
	}

	private getAdminNavLinks = () : ILink[][] => {
		// % protected region % [Add custom logic before all here] off begin
		// % protected region % [Add custom logic before all here] end

		const links = getPageLinks();
		const userLinks = links.filter(link => link.isMember).filter(this.filter);
		const entityLinks = links.filter(link => !link.isMember).filter(this.filter);
		const linkGroups: ILink[][] = [];

		// % protected region % [Add any custom logic here before groups are made] off begin
		// % protected region % [Add any custom logic here before groups are made] end

		const homeLinkGroup: ILink[] = [
			{
				path: '/admin',
				label: 'Home',
				icon: 'home',
				iconPos: 'icon-left',
			},
			// % protected region % [Updated your home link group here] off begin
			// % protected region % [Updated your home link group here] end
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{ path: '/admin/user', label: 'All Users' },
						...userLinks.map(link => ({ path: link.path, label: link.label })),
					],
				},
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => {
						return {
							path: link.path,
							label: link.label,
						};
					}),
				},
			);
		}
		linkGroups.push(entityLinkGroup);

		// % protected region % [Add any new link groups here before other and bottom] off begin
		// % protected region % [Add any new link groups here before other and bottom] end

		const otherlinkGroup: ILink[] = [];

		// % protected region % [Add any additional links to otherLinkGroup here] off begin
		// % protected region % [Add any additional links to otherLinkGroup here] end

		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			// % protected region % [Modify the utility link group here] off begin
			{
				path: '/admin/',
				label: 'Utility',
				icon: 'file',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/styleguide',
						label: 'Style Guide',
						useATag: false,
					},
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
					{
						path: 'https://gitlab.codebots.dev',
						label: 'Git',
						useATag: true,
					},
				],
			},
			// % protected region % [Modify the utility link group here] end
		);

		// % protected region % [Update the logout link here] off begin
		bottomlinkGroup.push(
			{
				path: '/logout',
				label: 'Logout',
				icon: 'room',
				iconPos: 'icon-left',
			},
		);
		// % protected region % [Update the logout link here] end

		// % protected region % [Add any additional links to bottomlinkGroup here] off begin
		// % protected region % [Add any additional links to bottomlinkGroup here] end

		linkGroups.push(bottomlinkGroup);

		// % protected region % [Modify your link groups here before returning] off begin
		// % protected region % [Modify your link groups here before returning] end

		return linkGroups;
	}

	// % protected region % [Add custom methods here] off begin
	// % protected region % [Add custom methods here] end
}
