/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBusinessEntityEntity from 'Models/Security/Acl/VisitorsBusinessEntityEntity';
import UserBusinessEntityEntity from 'Models/Security/Acl/UserBusinessEntityEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { isPhone } from 'Validators/Functions/Custom';
import { isEmail } from 'Validators/Functions/Email';
import { runInAction } from 'mobx';
import { AddressEntity, TrustEntityCodesEntity } from 'Models/Entities';
import { verifyAbn, verifyAcn } from 'Util/StringUtils';
import { store } from '../Store';
// % protected region % [Add any further imports here] end

export interface IBusinessEntityAttributes extends IModelAttributes {
	enabledForMonitor: boolean;
	entityName: string;
	trusteeName: string;
	trusteeACN: string;
	acnEntityName: string;
	isActive: boolean;
	finance: boolean;
	enabledForApprove: boolean;
	creditorID: string;
	trusteeACNLastUpdated: Date;
	trusteeACNEntityTypeCode: string;
	phone: string;
	enabledForPPSR: boolean;
	trusteeACNEntityName: string;
	entityStatus: string;
	supplyGnE: boolean;
	email: string;
	legacyID: number;
	entityTypeCode: string;
	hire: boolean;
	acnEntityTypeCode: string;
	dateOfBirth: Date;
	abn: string;
	useOrganisationEmail: boolean;
	name: string;
	abrLastUpdated: Date;
	acn: string;
	acnStatus: string;
	acnLastUpdated: Date;
	enableEmailNotifications: boolean;
	entityTypeDescription: string;
	monitorNotificationsEmail: string;
	summary: string;
	trusteeACNStatus: string;
	paymentCustomerId: string;
	paymentMethodId: string;
	paymentExpiryDate: Date;

	atbFiles: Array<
		| Models.AtbFileEntity
		| Models.IAtbFileEntityAttributes
	>;
	address?: Models.AddressEntity |
		Models.IAddressEntityAttributes;
	applicationss: Array<
		| Models.ApplicationEntity
		| Models.IApplicationEntityAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	customerss: Array<
		| Models.CustomerEntity
		| Models.ICustomerEntityAttributes
	>;
	esigntemplatess: Array<
		| Models.EsigntemplateEntity
		| Models.IEsigntemplateEntityAttributes
	>;
	logoss: Array<
		| Models.LogoEntity
		| Models.ILogoEntityAttributes
	>;
	primaryOrganisation?: Models.OrganisationEntity |
		Models.IOrganisationEntityAttributes;
	reportRequests: Array<
		| Models.ReportRequestEntity
		| Models.IReportRequestEntityAttributes
	>;
	spgss: Array<
		| Models.SpgEntity
		| Models.ISpgEntityAttributes
	>;
	users: Array<
		| Models.BusinessEntityUser
		| Models.IBusinessEntityUserAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	integrationXeroTenantId?: string;
	integrationXeroTenantName?: string;
	automatedAtbProcessingDayOfMonth?: number;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BusinessEntity', 'Business Entity')
// % protected region % [Customise your entity metadata here] end
export default class BusinessEntity extends Model
	implements IBusinessEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBusinessEntityEntity(),
		new UserBusinessEntityEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Enabled For Monitor'] off begin
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Enabled For Monitor',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public enabledForMonitor: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Enabled For Monitor'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Name'] off begin
	/**
	 * Entity Name retrieve from ABN lookup
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Entity Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee Name'] off begin
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Trustee Name',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN'] off begin
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Trustee ACN',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACN: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Name'] off begin
	/**
	 * The entity name sent back in the ACN response
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'ACN Entity Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnEntityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] off begin
	/**
	 * Is Active
	 */
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Finance'] off begin
	/**
	 * Is the business of type Finance
	 */
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Finance',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public finance: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Finance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enabled for Approve'] off begin
	/**
	 * Enabled for Approve
	 */
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Enabled for Approve',
		displayType: 'checkbox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public enabledForApprove: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Enabled for Approve'] end

	// % protected region % [Modify props to the crud options here for attribute 'Creditor ID'] off begin
	/**
	 * The Creditor ID is used in the ATB reports
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Creditor ID',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public creditorID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Creditor ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Last Updated'] off begin
	/**
	 * Trustee ACN Last Updated
	 */
	@observable
	@attribute<BusinessEntity, Date>()
	@CRUD({
		name: 'Trustee ACN Last Updated',
		displayType: 'datetimepicker',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public trusteeACNLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Type Code'] off begin
	/**
	 * The entity type code for the trustee ACN reponse
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Trustee ACN Entity Type Code',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNEntityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * Phone
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enabled for PPSR'] off begin
	/**
	 * Enabled for PPSR
	 */
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Enabled for PPSR',
		displayType: 'checkbox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public enabledForPPSR: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Enabled for PPSR'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Name'] off begin
	/**
	 * The entity name sent back by the trustee ACN response
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Trustee ACN Entity Name',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNEntityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Status'] off begin
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Entity Status',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Supply GnE'] off begin
	/**
	 * Is the business of type Supply Goods and Equipment
	 */
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Supply GnE',
		displayType: 'checkbox',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public supplyGnE: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Supply GnE'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	/**
	 * Email
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Email',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] off begin
	/**
	 * Legacy ID
	 */
	@Validators.Integer()
	@Validators.Unique()
	@observable
	@attribute<BusinessEntity, number>()
	@CRUD({
		name: 'Legacy ID',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Code'] off begin
	/**
	 * Entity Type Code retrieve from ABN lookup
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Entity Type Code',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hire'] off begin
	/**
	 * Is the business of a Hire business type
	 */
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Hire',
		displayType: 'checkbox',
		order: 200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hire: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hire'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Type Code'] off begin
	/**
	 * The entity type code sent back in the ACN response
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'ACN Entity Type Code',
		displayType: 'textfield',
		order: 210,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnEntityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] off begin
	/**
	 * DoB field uses for sole traders
	 */
	@observable
	@attribute<BusinessEntity, Date>()
	@CRUD({
		name: 'Date of Birth',
		displayType: 'datetimepicker',
		order: 220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN'] off begin
	/**
	 * ABN
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'ABN',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN'] end

	// % protected region % [Modify props to the crud options here for attribute 'Use Organisation Email'] off begin
	/**
	 * Boolean for determining to use the organisation email instead of the saved one.
	 */
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Use Organisation Email',
		displayType: 'checkbox',
		order: 240,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public useOrganisationEmail: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Use Organisation Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Name
	 */
	@Validators.Required()
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 250,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABR Last Updated'] off begin
	/**
	 * ABR Last Updated
	 */
	@observable
	@attribute<BusinessEntity, Date>()
	@CRUD({
		name: 'ABR Last Updated',
		displayType: 'datetimepicker',
		order: 260,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public abrLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'ABR Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN'] off begin
	/**
	 * ACN
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'ACN',
		displayType: 'textfield',
		order: 270,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Status'] off begin
	/**
	 * Status of the ACN
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'ACN Status',
		displayType: 'textfield',
		order: 280,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Last Updated'] off begin
	/**
	 * ACN Last Updated
	 */
	@observable
	@attribute<BusinessEntity, Date>()
	@CRUD({
		name: 'ACN Last Updated',
		displayType: 'datetimepicker',
		order: 290,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public acnLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enable Email Notifications'] off begin
	@observable
	@attribute<BusinessEntity, boolean>()
	@CRUD({
		name: 'Enable Email Notifications',
		displayType: 'checkbox',
		order: 300,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public enableEmailNotifications: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Enable Email Notifications'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Description'] off begin
	/**
	 * Entity Type Description retrieve from ABN lookup
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Entity Type Description',
		displayType: 'textfield',
		order: 310,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityTypeDescription: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Monitor Notifications Email'] off begin
	@Validators.Email()
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Monitor Notifications Email',
		displayType: 'textfield',
		order: 320,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public monitorNotificationsEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Monitor Notifications Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Summary'] off begin
	/**
	 * Brief summary of the business
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Summary',
		displayType: 'textarea',
		order: 330,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public summary: string;
	// % protected region % [Modify props to the crud options here for attribute 'Summary'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Status'] off begin
	/**
	 * Trustee ACN Status
	 */
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Trustee ACN Status',
		displayType: 'textfield',
		order: 340,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Payment Customer Id'] off begin
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Payment Customer Id',
		displayType: 'textfield',
		order: 350,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public paymentCustomerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Payment Customer Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Payment Method Id'] off begin
	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Payment Method Id',
		displayType: 'textfield',
		order: 360,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public paymentMethodId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Payment Method Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Payment Expiry Date'] off begin
	@observable
	@attribute<BusinessEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Payment Expiry Date',
		displayType: 'datepicker',
		order: 370,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public paymentExpiryDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Payment Expiry Date'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATB File'] off begin
		name: 'ATB Files',
		displayType: 'reference-multicombobox',
		order: 380,
		referenceTypeFunc: () => Models.AtbFileEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'atbFiles',
			oppositeEntity: () => Models.AtbFileEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ATB File'] end
	})
	public atbFiles: Models.AtbFileEntity[] = [];

	/**
	 * Address
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Address'] off begin
		name: 'Address',
		displayType: 'reference-combobox',
		order: 390,
		referenceTypeFunc: () => Models.AddressEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'addresss',
			oppositeEntity: () => Models.AddressEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Address'] end
	})
	public address?: Models.AddressEntity;

	/**
	 * Approve Applications
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Applications'] off begin
		name: 'Applicationss',
		displayType: 'reference-multicombobox',
		order: 400,
		referenceTypeFunc: () => Models.ApplicationEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'applicationss',
			oppositeEntity: () => Models.ApplicationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Applications'] end
	})
	public applicationss: Models.ApplicationEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 410,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customers'] off begin
		name: 'Customerss',
		displayType: 'reference-multicombobox',
		order: 420,
		referenceTypeFunc: () => Models.CustomerEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customerss',
			oppositeEntity: () => Models.CustomerEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Customers'] end
	})
	public customerss: Models.CustomerEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplates'] off begin
		name: 'ESignTemplatess',
		displayType: 'reference-multicombobox',
		order: 430,
		referenceTypeFunc: () => Models.EsigntemplateEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esigntemplatess',
			oppositeEntity: () => Models.EsigntemplateEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplates'] end
	})
	public esigntemplatess: Models.EsigntemplateEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Logos'] off begin
		name: 'Logoss',
		displayType: 'reference-multicombobox',
		order: 440,
		referenceTypeFunc: () => Models.LogoEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'logoss',
			oppositeEntity: () => Models.LogoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Logos'] end
	})
	public logoss: Models.LogoEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Primary Organisation'] off begin
		name: 'Primary Organisation',
		displayType: 'reference-combobox',
		order: 450,
		referenceTypeFunc: () => Models.OrganisationEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'primaryOrganisations',
			oppositeEntity: () => Models.OrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Primary Organisation'] end
	})
	public primaryOrganisation?: Models.OrganisationEntity;

	@Validators.Length(1)
	@Validators.Required()
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Requests',
		displayType: 'reference-multicombobox',
		order: 460,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportRequests',
			oppositeEntity: () => Models.ReportRequestEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequests: Models.ReportRequestEntity[] = [];

	/**
	 * PPSR SPGs
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'SPGs'] off begin
		name: 'SPGss',
		displayType: 'reference-multicombobox',
		order: 470,
		referenceTypeFunc: () => Models.SpgEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'spgss',
			oppositeEntity: () => Models.SpgEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'SPGs'] end
	})
	public spgss: Models.SpgEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'User',
		displayType: 'reference-multicombobox',
		order: 480,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BusinessEntityUser,
		optionEqualFunc: makeJoinEqualsFunc('userId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'businessEntity',
			oppositeEntityName: 'userEntity',
			relationName: 'businessEntity',
			relationOppositeName: 'user',
			entity: () => Models.BusinessEntity,
			joinEntity: () => Models.BusinessEntityUser,
			oppositeEntity: () => Models.UserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public users: Models.BusinessEntityUser[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	// WHEN ADDING EXTRA ATTRIBUTES remember to add them to the clear fields function

	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Integration Xero Tenant Id',
		displayType: 'textfield',
		order: 430,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseString,
	})
	public integrationXeroTenantId?: string;

	@observable
	@attribute<BusinessEntity, string>()
	@CRUD({
		name: 'Integration Xero Tenant Name',
		displayType: 'textfield',
		order: 440,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public integrationXeroTenantName?: string;

	@Validators.Integer()
	@observable
	@attribute<BusinessEntity, number>()
	@CRUD({
		name: 'Integration Schedule Day Of Month',
		displayType: 'textfield',
		order: 450,
		headerColumn: true,
		searchable: false,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public automatedAtbProcessingDayOfMonth?: number;

	// Is true when the ABN validation process has finished
	@observable public abnFinishedValidating: boolean = false;
	@observable public trusteeAcnFinishedValidating: boolean = false;
	@observable public acnFinishedValidating: boolean = false;

	// If empty string radio button is empty, if No trusteeName needs to be filled, if Yes trusteeAcn needs to be filled
	@observable public useCorporateTrusteeACN: ('' | 'Yes' | 'No') = '';

	// Will validate a field, then update the errors object
	@action public validateField = async (field: string) => {
		switch (field) {
			case 'name': {
				this.setFieldError('name', !this.name, 'Organisation Name cannot be empty');
				break;
			}
			case 'phone': {
				this.setFieldError('phone', !!this.phone && !isPhone(this.phone), 'Phone Number must be valid');
				break;
			}
			case 'abn': {
				this.abnFinishedValidating = false;

				if (!this.abn) {
					this.setFieldError('abn', true, 'ABN cannot be empty');
					this.clearAbnFields();
					break;
				}

				if (this.abn.replace(/\D/g, '').length < 11) {
					this.setFieldError('abn', true, 'The ABN must contain 11 digits');
					this.clearAbnFields();
					break;
				}

				const [response, trusteeCodes] = await Promise.all(
					[verifyAbn(this.abn), TrustEntityCodesEntity.trusteeCodes()],
				);

				runInAction(() => {
					this.abnFinishedValidating = true;

					if (response.exception !== null) {
						this.setFieldError('abn', true, response.exception.exceptionDescription);
						this.clearAbnFields();
						if (response.exception.exceptionDescription.includes('No records found')) {
							this.entityStatus = 'NO RECORD';
						} else {
							this.entityStatus = response.exception.exceptionDescription;
						}
					} else {
						this.entityTypeCode = response.businessEntity.entityType.entityTypeCode;
						this.entityTypeDescription = response.businessEntity.entityType.entityDescription;
						this.entityStatus = response.businessEntity.entityStatus.entityStatusCode;
						if (this.entityTypeCode === 'IND') {
							const { givenName, otherGivenName, familyName } = response.businessEntity.legalName;
							this.entityName = [givenName, otherGivenName, familyName].join(' ');
						} else {
							this.entityName = response.businessEntity.mainName.organisationName;
						}
						this.abrLastUpdated = moment().utc().toDate();
						this.setFieldError('abn', false, '');
						if (this.entityStatus !== 'Active') {
							this.setFieldError('abn', true, 'The ABN must be currently active');
						}
						if (!!response.businessEntity.asicNumber && !this.acn) {
							this.acn = response.businessEntity.asicNumber;
							this.validateField('acn');
						}
						// Depending on the entity type code, we may have to unset other fields and errors
						if (this.entityTypeCode === 'IND') {
							this.trusteeACN = '';
							this.setFieldError('trusteeACN', false, '');
							this.trusteeName = '';
							this.setFieldError('trusteeName', false, '');
						} else if (trusteeCodes.includes(this.entityTypeCode)) {
							// @ts-ignore
							this.dateOfBirth = undefined;
							this.setFieldError('dob', false, '');
						} else {
							// Not a trust or an individual, so none of these fields are useful
							this.trusteeACN = '';
							this.setFieldError('trusteeACN', false, '');
							this.trusteeName = '';
							this.setFieldError('trusteeName', false, '');
							// @ts-ignore
							this.dateOfBirth = undefined;
							this.setFieldError('dob', false, '');
						}
					}
				});
				break;
			}
			case 'acn': {
				this.acnFinishedValidating = false;
				if (!!this.acn) {
					if (this.acn.replace(/\D/g, '').length < 9) {
						runInAction(() => {
							this.setFieldError('acn', true, 'The ACN must contain 9 digits');
							this.clearAcnFields();
						});
						break;
					}

					const response = await verifyAcn(this.acn);
					runInAction(() => {
						this.acnFinishedValidating = true;

						if (!!response && !!response.exception) {
							this.setFieldError('acn', true, response.exception.exceptionDescription);
							this.clearAcnFields();
							this.acnStatus = response.exception.exceptionDescription;
						} else {
							this.acnLastUpdated = moment().utc().toDate();
							if (response.result.records.length === 0) {
								this.clearAcnFields();
								this.acnStatus = 'NO RECORD';
								this.setFieldError('acn', true, 'No results found');
							} else {
								const { records } = response.result;
								// valid records are ones with the status set to REGD
								const validRecords = records.filter((x: { Status: string }) => x.Status === 'REGD');
								// ACN is valid if there is at least one REGD status ACN
								if (validRecords.length === 0) {
									// we grab the first non REGD status and set to ACN status
									this.clearAcnFields();
									this.acnStatus = records
										.filter((x: { Status: string }) => x.Status !== 'REGD')[0]
										.Status;
									this.setFieldError('acn', true, 'The ACN must be currently active');
								} else {
									this.acnStatus = 'REGD';
									this.acnEntityTypeCode = records[0].Type;
									this.acnEntityName = records
										.filter((x: { 'Current Name Indicator': string }) => x['Current Name Indicator']
											=== 'Y')[0]['Company Name'];
									this.setFieldError('acn', false, '');
								}
							}
						}
					});
					break;
				}
				this.setFieldError('acn', false, '');
				this.clearAcnFields();
				break;
			}
			case 'trusteeACN': {
				if (this.useCorporateTrusteeACN !== 'Yes') {
					this.setFieldError('trusteeACN', false, '');
					break;
				}
				this.trusteeAcnFinishedValidating = false;

				// acn field is only relevant for trusts and if the trust name is also empty
				const trusteeCodes = await TrustEntityCodesEntity.trusteeCodes();
				if (this.entityTypeCode && trusteeCodes.includes(this.entityTypeCode) && !this.trusteeName) {
					if (!this.trusteeACN) {
						runInAction(() => {
							this.clearTrusteeAcnFields();
							this.setFieldError('trusteeACN', true, 'Trustee ACN cannot be empty');
						});
						break;
					}

					if (this.trusteeACN.replace(/\D/g, '').length < 9) {
						runInAction(() => {
							this.clearTrusteeAcnFields();
							this.setFieldError('trusteeACN', true, 'The ACN must contain 9 digits');
						});
						break;
					}

					const response = await verifyAcn(this.trusteeACN);
					runInAction(() => {
						this.trusteeAcnFinishedValidating = true;

						if (!!response && !!response.exception) {
							this.setFieldError('trusteeACN', true, response.exception.exceptionDescription);
							this.clearTrusteeAcnFields();
							this.trusteeACNStatus = response.exception.exceptionDescription;
						} else {
							this.trusteeACNLastUpdated = moment().utc().toDate();
							if (response.result.records.length === 0) {
								this.clearTrusteeAcnFields();
								this.trusteeACNStatus = 'NO RECORD';
								this.setFieldError('trusteeACN', true, 'No records found');
							} else {
								const { records } = response.result;
								// valid records are ones with the status set to REGD
								const validRecords = records.filter((x: { Status: string }) => x.Status === 'REGD');
								// ACN is valid if there is at least one REGD status ACN
								if (validRecords.length === 0) {
									// we grab the first non REGD status and set to ACN status
									this.clearTrusteeAcnFields();
									this.trusteeACNStatus = records
										.filter((x: { Status: string }) => x.Status !== 'REGD')[0]
										.Status;
									this.setFieldError('trusteeACN', true, 'The ACN must be currently active');
								} else {
									this.trusteeACNStatus = 'REGD';
									this.trusteeACNEntityTypeCode = records[0].Type;
									this.trusteeACNEntityName = records
										.filter((x: { 'Current Name Indicator': string }) => x['Current Name Indicator']
											=== 'Y')[0]['Company Name'];
									this.setFieldError('trusteeACN', false, '');
								}
							}
						}
					});
				} else {
					this.setFieldError('trusteeACN', false, '');
				}
				break;
			}
			case 'trusteeName': {
				if (this.useCorporateTrusteeACN !== 'No') {
					this.setFieldError('trusteeName', false, '');
					break;
				}
				// trusteeName field is only releveant for trusts and if the trusteeACN is also empty
				const trusteeCodes = await TrustEntityCodesEntity.trusteeCodes();
				runInAction(() => {
					if (this.entityTypeCode
						&& trusteeCodes.includes(this.entityTypeCode)
						&& !this.trusteeACN
					) {
						if (!this.trusteeName) {
							this.setFieldError('trusteeName', true, 'Trustee name cannot be empty');
							return;
						}
					}
					this.setFieldError('trusteeName', false, '');
				});
				break;
			}
			case 'dob': {
				// DOB field is only relevant for individuals/sole traders
				if (this.entityTypeCode && this.entityTypeCode === 'IND') {
					if (!this.dateOfBirth) {
						this.setFieldError('dob', true, 'Date of birth cannot be empty');
						break;
					}
					if (moment(this.dateOfBirth).isAfter()) {
						this.setFieldError('dob', true, 'Invalid Date of birth');
						break;
					}
				}
				this.setFieldError('dob', false, '');
				break;
			}
			case 'email': {
				if (!this.useOrganisationEmail && !this.email) {
					this.setFieldError('email', true, 'Email Address cannot be empty');
				} else if (!this.useOrganisationEmail && !isEmail(this.email)) {
					this.setFieldError('email', true, 'Email Address must be valid');
				} else {
					this.setFieldError('email', false, '');
				}
				break;
			}
			case 'ppsrEmail': {
				if (this.enabledForPPSR) {
					if (!this.spgss[0].email) {
						this.setFieldError('ppsrEmail', true, 'Email Address cannot be empty');
						break;
					}
					if (!isEmail(this.spgss[0].email)) {
						this.setFieldError('ppsrEmail', true, 'Email Address must be valid');
						break;
					}
				}
				this.setFieldError('ppsrEmail', false, '');
				break;
			}
			case 'approveEmail': {
				if (this.enabledForApprove) {
					if (!this.applicationss[0].email) {
						this.setFieldError('approveEmail', true, 'Email Address cannot be empty');
						break;
					}
					if (!isEmail(this.applicationss[0].email)) {
						this.setFieldError('approveEmail', true, 'Email Address must be valid');
						break;
					}
				}
				this.setFieldError('approveEmail', false, '');
				break;
			}
			case 'monitorNotificationsEmail': {
				if (!this.enabledForMonitor) {
					// Never validate this field
					this.setFieldError('monitorNotificationsEmail', false, '');
					break;
				}

				if (this.enableEmailNotifications && !this.monitorNotificationsEmail) {
					this.setFieldError('monitorNotificationsEmail', true, 'Email Address cannot be empty');
					break;
				}
				if (this.monitorNotificationsEmail && !isEmail(this.monitorNotificationsEmail)) {
					this.setFieldError('monitorNotificationsEmail', true, 'Email Address must be valid');
					break;
				}
				this.setFieldError('monitorNotificationsEmail', false, '');
				break;
			}
			case 'applicationName': {
				this.setFieldError(
					'applicationName',
					this.enabledForApprove && !this.applicationss[0].applicationName,
					'Application Name cannot be empty',
				);
				break;
			}
			case 'summary': {
				if (this.enabledForPPSR) {
					this.setFieldError('summary', !this.summary, 'Summary cannot be empty');
				}
				break;
			}
			case 'address.postcode': {
				// Borrow errors from the address entity
				await this.address?.validateField('postcode');
				this.setFieldError(
					'address.postcode',
					!!this.address?.errors.postcode,
					this.address?.errors?.postcode ?? '',
				);
				break;
			}
		}
	};

	@action public validateReferralPartnerField = async (field: string) => {
		switch (field) {
			case 'abn': {
				if (!!this.abn) {
					await this.validateField(field);
				} else {
					this.clearAbnFields();
					this.abnFinishedValidating = false;
				}
				break;
			}
			default: {
				await this.validateField(field);
				break;
			}
		}
	};

	public validateDetailsFields = async () => {
		const fieldList = [
			'name', 'phone', 'abn', 'acn', 'trusteeACN', 'trusteeName', 'dob', 'email', 'address.postcode', 'summary',
		];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	};

	public validateReferralPartnerFields = async () => {
		const fieldList = [
			'name', 'phone', 'abn', 'email',
		];
		const validationPromises = fieldList.map(field => this.validateReferralPartnerField(field));
		await Promise.all(validationPromises);
	};

	public validatePpsrFields = async () => {
		const fieldList = ['summary', 'ppsrEmail'];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	};

	public validateMonitorFields = async () => {
		const fieldList = ['monitorNotificationsEmail'];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	};
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBusinessEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBusinessEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.enabledForMonitor !== undefined) {
				this.enabledForMonitor = attributes.enabledForMonitor;
			}
			if (attributes.entityName !== undefined) {
				this.entityName = attributes.entityName;
			}
			if (attributes.trusteeName !== undefined) {
				this.trusteeName = attributes.trusteeName;
			}
			if (attributes.trusteeACN !== undefined) {
				this.trusteeACN = attributes.trusteeACN;
			}
			if (attributes.acnEntityName !== undefined) {
				this.acnEntityName = attributes.acnEntityName;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.finance !== undefined) {
				this.finance = attributes.finance;
			}
			if (attributes.enabledForApprove !== undefined) {
				this.enabledForApprove = attributes.enabledForApprove;
			}
			if (attributes.creditorID !== undefined) {
				this.creditorID = attributes.creditorID;
			}
			if (attributes.trusteeACNLastUpdated !== undefined) {
				if (attributes.trusteeACNLastUpdated === null) {
					this.trusteeACNLastUpdated = attributes.trusteeACNLastUpdated;
				} else {
					this.trusteeACNLastUpdated = moment(attributes.trusteeACNLastUpdated).toDate();
				}
			}
			if (attributes.trusteeACNEntityTypeCode !== undefined) {
				this.trusteeACNEntityTypeCode = attributes.trusteeACNEntityTypeCode;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.enabledForPPSR !== undefined) {
				this.enabledForPPSR = attributes.enabledForPPSR;
			}
			if (attributes.trusteeACNEntityName !== undefined) {
				this.trusteeACNEntityName = attributes.trusteeACNEntityName;
			}
			if (attributes.entityStatus !== undefined) {
				this.entityStatus = attributes.entityStatus;
			}
			if (attributes.supplyGnE !== undefined) {
				this.supplyGnE = attributes.supplyGnE;
			}
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.legacyID !== undefined) {
				this.legacyID = attributes.legacyID;
			}
			if (attributes.entityTypeCode !== undefined) {
				this.entityTypeCode = attributes.entityTypeCode;
			}
			if (attributes.hire !== undefined) {
				this.hire = attributes.hire;
			}
			if (attributes.acnEntityTypeCode !== undefined) {
				this.acnEntityTypeCode = attributes.acnEntityTypeCode;
			}
			if (attributes.dateOfBirth !== undefined) {
				if (attributes.dateOfBirth === null) {
					this.dateOfBirth = attributes.dateOfBirth;
				} else {
					this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
				}
			}
			if (attributes.abn !== undefined) {
				this.abn = attributes.abn;
			}
			if (attributes.useOrganisationEmail !== undefined) {
				this.useOrganisationEmail = attributes.useOrganisationEmail;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.abrLastUpdated !== undefined) {
				if (attributes.abrLastUpdated === null) {
					this.abrLastUpdated = attributes.abrLastUpdated;
				} else {
					this.abrLastUpdated = moment(attributes.abrLastUpdated).toDate();
				}
			}
			if (attributes.acn !== undefined) {
				this.acn = attributes.acn;
			}
			if (attributes.acnStatus !== undefined) {
				this.acnStatus = attributes.acnStatus;
			}
			if (attributes.acnLastUpdated !== undefined) {
				if (attributes.acnLastUpdated === null) {
					this.acnLastUpdated = attributes.acnLastUpdated;
				} else {
					this.acnLastUpdated = moment(attributes.acnLastUpdated).toDate();
				}
			}
			if (attributes.enableEmailNotifications !== undefined) {
				this.enableEmailNotifications = attributes.enableEmailNotifications;
			}
			if (attributes.entityTypeDescription !== undefined) {
				this.entityTypeDescription = attributes.entityTypeDescription;
			}
			if (attributes.monitorNotificationsEmail !== undefined) {
				this.monitorNotificationsEmail = attributes.monitorNotificationsEmail;
			}
			if (attributes.summary !== undefined) {
				this.summary = attributes.summary;
			}
			if (attributes.trusteeACNStatus !== undefined) {
				this.trusteeACNStatus = attributes.trusteeACNStatus;
			}
			if (attributes.paymentCustomerId !== undefined) {
				this.paymentCustomerId = attributes.paymentCustomerId;
			}
			if (attributes.paymentMethodId !== undefined) {
				this.paymentMethodId = attributes.paymentMethodId;
			}
			if (attributes.paymentExpiryDate !== undefined) {
				if (attributes.paymentExpiryDate === null) {
					this.paymentExpiryDate = attributes.paymentExpiryDate;
				} else {
					this.paymentExpiryDate = moment(attributes.paymentExpiryDate).toDate();
				}
			}
			if (attributes.atbFiles !== undefined && Array.isArray(attributes.atbFiles)) {
				for (const model of attributes.atbFiles) {
					if (model instanceof Models.AtbFileEntity) {
						this.atbFiles.push(model);
					} else {
						this.atbFiles.push(new Models.AtbFileEntity(model));
					}
				}
			}
			if (attributes.address !== undefined) {
				if (attributes.address === null) {
					this.address = attributes.address;
				} else if (attributes.address instanceof Models.AddressEntity) {
					this.address = attributes.address;
				} else {
					this.address = new Models.AddressEntity(attributes.address);
				}
			}
			if (attributes.applicationss !== undefined && Array.isArray(attributes.applicationss)) {
				for (const model of attributes.applicationss) {
					if (model instanceof Models.ApplicationEntity) {
						this.applicationss.push(model);
					} else {
						this.applicationss.push(new Models.ApplicationEntity(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.customerss !== undefined && Array.isArray(attributes.customerss)) {
				for (const model of attributes.customerss) {
					if (model instanceof Models.CustomerEntity) {
						this.customerss.push(model);
					} else {
						this.customerss.push(new Models.CustomerEntity(model));
					}
				}
			}
			if (attributes.esigntemplatess !== undefined && Array.isArray(attributes.esigntemplatess)) {
				for (const model of attributes.esigntemplatess) {
					if (model instanceof Models.EsigntemplateEntity) {
						this.esigntemplatess.push(model);
					} else {
						this.esigntemplatess.push(new Models.EsigntemplateEntity(model));
					}
				}
			}
			if (attributes.logoss !== undefined && Array.isArray(attributes.logoss)) {
				for (const model of attributes.logoss) {
					if (model instanceof Models.LogoEntity) {
						this.logoss.push(model);
					} else {
						this.logoss.push(new Models.LogoEntity(model));
					}
				}
			}
			if (attributes.primaryOrganisation !== undefined) {
				if (attributes.primaryOrganisation === null) {
					this.primaryOrganisation = attributes.primaryOrganisation;
				} else if (attributes.primaryOrganisation instanceof Models.OrganisationEntity) {
					this.primaryOrganisation = attributes.primaryOrganisation;
				} else {
					this.primaryOrganisation = new Models.OrganisationEntity(attributes.primaryOrganisation);
				}
			}
			if (attributes.reportRequests !== undefined && Array.isArray(attributes.reportRequests)) {
				for (const model of attributes.reportRequests) {
					if (model instanceof Models.ReportRequestEntity) {
						this.reportRequests.push(model);
					} else {
						this.reportRequests.push(new Models.ReportRequestEntity(model));
					}
				}
			}
			if (attributes.spgss !== undefined && Array.isArray(attributes.spgss)) {
				for (const model of attributes.spgss) {
					if (model instanceof Models.SpgEntity) {
						this.spgss.push(model);
					} else {
						this.spgss.push(new Models.SpgEntity(model));
					}
				}
			}
			if (attributes.users !== undefined && Array.isArray(attributes.users)) {
				for (const model of attributes.users) {
					if (model instanceof Models.BusinessEntityUser) {
						this.users.push(model);
					} else {
						this.users.push(new Models.BusinessEntityUser(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.integrationXeroTenantId !== undefined) {
				this.integrationXeroTenantId = attributes.integrationXeroTenantId;
			}
			if (attributes.integrationXeroTenantName !== undefined) {
				this.integrationXeroTenantName = attributes.integrationXeroTenantName;
			}
			if (attributes.automatedAtbProcessingDayOfMonth !== undefined) {
				this.automatedAtbProcessingDayOfMonth = attributes.automatedAtbProcessingDayOfMonth;
			}

			// ensure the observable is set correctly.
			if (!attributes.trusteeACN && !attributes.trusteeName) {
				this.useCorporateTrusteeACN = '';
			} else if (attributes.trusteeACN) {
				this.useCorporateTrusteeACN = 'Yes';
			} else if (attributes.trusteeName) {
				this.useCorporateTrusteeACN = 'No';
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		users {
			${Models.BusinessEntityUser.getAttributes().join('\n')}
			user {
				${Models.UserEntity.getAttributes().join('\n')}
			}
		}
		atbFiles {
			${Models.AtbFileEntity.getAttributes().join('\n')}
		}
		address {
			${Models.AddressEntity.getAttributes().join('\n')}
		}
		applicationss {
			${Models.ApplicationEntity.getAttributes().join('\n')}
			${Models.ApplicationEntity.getFiles().map(f => f.name).join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
		customerss {
			${Models.CustomerEntity.getAttributes().join('\n')}
		}
		esigntemplatess {
			${Models.EsigntemplateEntity.getAttributes().join('\n')}
		}
		logoss {
			${Models.LogoEntity.getAttributes().join('\n')}
			${Models.LogoEntity.getFiles().map(f => f.name).join('\n')}
		}
		primaryOrganisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
		reportRequests {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
		spgss {
			${Models.SpgEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			users: {},
			atbFiles: {},
			address: {},
			applicationss: {},
			customerss: {},
			esigntemplatess: {},
			logoss: {},
			primaryOrganisation: {},
			reportRequests: {},
			spgss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'address',
							'applicationss',
							'esigntemplatess',
							'logoss',
							'primaryOrganisation',
							'reportRequests',
							'spgss',
							'users',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public static IsLinkedToUser(businessEntity: BusinessEntity): boolean {
		const { commonViewBusinessEntities } = store.userPermissions;

		if (commonViewBusinessEntities === 'NONE') {
			return false;
		}
		if (commonViewBusinessEntities === 'ALL' || commonViewBusinessEntities === 'WITHIN_ORGANISATION') {
			return true;
		}

		return store.getUser?.businessEntityIds?.includes(businessEntity.id) ?? false;
	}

	public updateMontiorStatus() {
		// eslint-disable-next-line max-len
		this.enabledForMonitor = true;
	}

	// Use ts-ignore because the C#Bot model doesn't type these as nullable, despite the fact that they don't have a
	// required attribute.
	@action public async removePpsrProduct() {
		// @ts-ignore
		this.hire = null;
		// @ts-ignore
		this.supplyGnE = null;
		// @ts-ignore
		this.finance = null;
		// @ts-ignore
		this.summary = null;
		this.enabledForPPSR = false;
		const promises = [];
		for (const reference of this.spgss) {
			reference.isActive = false;
			promises.push(reference.save());
		}
		await Promise.all(promises);
	}

	@action public async removeMonitorProduct() {
		this.enabledForMonitor = false;
		await this.save();
	}

	@action public async enableMonitorProduct() {
		this.enabledForMonitor = true;
	}

	@action public clearAbnFields() {
		this.entityName = '';
		this.entityTypeDescription = '';
		this.entityStatus = '';
		this.entityTypeCode = '';
	}

	@action public clearAcnFields() {
		this.acnEntityTypeCode = '';
		this.acnEntityName = '';
		this.acnStatus = '';
	}

	@action public clearTrusteeAcnFields() {
		this.trusteeACNEntityName = '';
		this.trusteeACNEntityTypeCode = '';
		this.trusteeACNStatus = '';
	}

	@action public async removeApproveProduct() {
		this.enabledForApprove = false;
		const promises = [];
		for (const reference of this.applicationss) {
			reference.isActive = false;
			promises.push(reference.save());
		}
		await Promise.all(promises);
	}

	// function to clear all the fields of a business entity. Including the extra observable fields
	@action public async clearFields() {
		const attributes = Models.BusinessEntity.getAttributes();
		attributes.forEach(attr => {
			this[attr] = undefined;
		});
		this.address = new AddressEntity();
		this.errors = {};
		this.integrationXeroTenantId = undefined;
		this.integrationXeroTenantName = undefined;
		this.automatedAtbProcessingDayOfMonth = undefined;
		this.abnFinishedValidating = false;
		this.trusteeAcnFinishedValidating = false;
		this.acnFinishedValidating = false;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BusinessEntity.prototype, 'created');
CRUD(modifiedAttr)(BusinessEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
