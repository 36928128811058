import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router';
import { observable, runInAction } from 'mobx';
import {
	CreditBureauEntity,
	RegistrationEntity,
	UserEntity,
	BusinessEntity,
	OrganisationEntity,
	OrganisationAuthorisedCreditBureau,
} from 'Models/Entities';
import { store } from 'Models/Store';
import Wizard, { WizardStepInformation } from 'Views/Components/Wizard/Wizard';
import RegistrationData from 'Models/Entities/RegistrationData';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import Spinner from 'Views/Components/Spinner/Spinner';
import setupApplicationsStep from 'Views/Components/Wizard/OrganisationRegistration/Approve/SetupApplicationsStep';
import AccountDetailsStep from 'Views/Components/Wizard/OrganisationRegistration/AccountDetailsStep';
import OrganisationDetailsStep from 'Views/Components/Wizard/OrganisationRegistration/OrganisationDetailsStep';
import PpsrLogo from 'Assets/Images/AccessPpsr_logo-white.svg';
import ApproveLogo from 'Assets/Images/AccessApprove_logo-white.svg';
import MonitorLogo from 'Assets/Images/AccessMonitor_logo-white.svg';
import BusinessEntityDetailsStep from 'Views/Components/Wizard/OrganisationRegistration/BusinessEntityDetailsStep';
import PpsrDetailsStep from 'Views/Components/Wizard/OrganisationRegistration/PpsrDetailsStep';
import ProductsStepSidebar from 'Views/Components/Wizard/OrganisationRegistration/ProductsStepSidebar';
import ProductsStep from 'Views/Components/Wizard/OrganisationRegistration/ProductsStep';
import PricingQuestionsStep from 'Views/Components/Wizard/OrganisationRegistration/PricingQuestionsStep';
import ReviewTermsStep from 'Views/Components/Wizard/OrganisationRegistration/Approve/ReviewTermsStep';
import IntelDisclosingStep from 'Views/Components/Wizard/OrganisationRegistration/IntelDisclosingStep';
import RegistrationSubmittedStep from 'Views/Components/Wizard/OrganisationRegistration/RegistrationSubmittedStep';
import alertToast from 'Util/ToastifyUtils';
import checkRegistrationAvailability from 'Util/CheckRegistrationAvailability';
import ShowProgressSavedModalPasscode from 'Views/Components/Wizard/ShowProgressSavedModalPasscode';
import moment from 'moment';

// Put the names in a separate list, so that we can reference them elsewhere
// If you edit the registration step list, make sure to edit this too
export const registrationStepNames = [
	'Register your details',
	'Organisation details',
	'Business entities',
	'About your organisation',
	'Product select',
	'Digital Online Application',
	'Secure Party Group',
	'Authorise Applications',
	'Review Terms & Conditions',
	'Registration Submitted',
];

const OrganisationRegistrationPage = observer((props: RouteComponentProps<{ registrationId?: string}>) => {
	const { match } = props;
	// state used to see if we need to re-run query as the passcode was validated
	const [passcodeState, setPassscodeState] = React.useState('unvalidated' as ('unvalidated' | 'validated'));

	// if cancel on the modal is pressed we close the modal and just change the path
	const onModalClose = () => {
		store.modal.hide();
		store.routerHistory.push('/hub/registration');
	};

	// sends the passcode email
	const sendPasscodeEmail = async (id: string): Promise<{ referringOrganisationId: string }> => {
		const result = await axios.post<{ referringOrganisationId: string }>(
			`${SERVER_URL}/api/entity/RegistrationEntity/saveProgress`,
			{
				Id: id,
				RegistrationType: 'Client',
			},
		);
		return result.data;
	};

	// function to return a new and empty registration
	const getNewRegistration = async () => {
		// this is so that the bureaus can be serialized properly
		// we do this because all bureaus are disclosed by default
		// so we do on initial registration creation as this is the one place
		// we guarantee that they have not edited anything.
		const bureaus = await CreditBureauEntity.creditBureaus();
		const authorisedCreditBureaus = bureaus.map(bureau => {
			return new OrganisationAuthorisedCreditBureau({
				authorisedCreditBureauId: bureau.id,
			});
		});
		return observable(new RegistrationEntity({
			parsedRegistrationData: new RegistrationData({
				user: new UserEntity(),
				organisation: new OrganisationEntity({
					authorisedCreditBureaus: authorisedCreditBureaus,
				}),
				businessEntitys: [new BusinessEntity()],
				page: 0,
			}),
			startDate: moment().utc().toDate(),
		}));
	};

	const response = useAsync<RegistrationEntity|null>(
		async () => {
			if (!match.params.registrationId) {
				return getNewRegistration();
			}

			// check the status of the registration. If the registration is completed or does not exist, raise a toast
			// and push to empty registration
			try {
				// this endpoint will thrown an error if exists or completed.
				await axios.post(
					`${SERVER_URL}/api/entity/RegistrationEntity/checkProgress/${match.params.registrationId}`,
				);
			} catch (exception) {
				alertToast('No matching registration exists. It may have already been completed. Otherwise, '
						+ 'you can start a new registration now.', 'error');
				store.routerHistory.push('/hub/registration');
				return getNewRegistration();
			}

			// if we haven't been validated check the passcode
			if (passcodeState === 'unvalidated') {
				await checkRegistrationAvailability(match.params.registrationId, setPassscodeState, onModalClose, sendPasscodeEmail);

				return null;
			}
			// if we have gotten here we know the passcode has been validated
			try {
				const result = await axios.post(
					`${SERVER_URL}/api/entity/RegistrationEntity/continue`,
					{
						id: `${match.params.registrationId}`,
					},
				);
				const registration = new RegistrationEntity(result.data);
				if (!registration.startDate) {
					registration.startDate = moment().utc().toDate();
				}
				return registration;
			} catch (e) {
				alertToast('No matching registration exists. It may have already been completed. Otherwise, '
						+ 'you can start a new registration now.', 'error');
				store.routerHistory.push('/hub/registration');
				return null;
			}
		},
		[passcodeState], // we re-run when the passcode has been validated
	);

	if (store.loggedIn) {
		return <Redirect to="/" />;
	}
	if (response.type === 'loading' || response.type === 'error') {
		return <Spinner />;
	}
	if (response.data === null) {
		return (null);
	}

	const registrationEntity = response.data as RegistrationEntity;

	// Describes the steps and the components
	// If you edit this list, make sure to edit the registrationStepNames list above too
	const wizardStepInformation: WizardStepInformation[] = [
		{
			name: 'Register your details',
			component: AccountDetailsStep,
			disableSaveProgress: true, // since the save progress needs an email we disable it on the first step
		},
		{
			name: 'Organisation details',
			component: OrganisationDetailsStep,
		},
		{
			name: 'Business entities',
			component: BusinessEntityDetailsStep,
		},
		{
			name: 'About your organisation',
			component: PricingQuestionsStep,
		},
		{
			name: 'Product select',
			component: ProductsStep,
			sidebarComponent: ProductsStepSidebar,
			hideTopBar: true,
		},
		{
			name: 'Setup Access Approve',
			component: setupApplicationsStep,
			minimisedUntilReached: true,
			hideSlide: !registrationEntity.parsedRegistrationData.organisation?.approveEnabled,
			sidebarSubtitle: 'Approve',
			topBarLogo: ApproveLogo,
			topBarType: 'approve',
		},
		{
			name: 'Setup Access PPSR',
			component: PpsrDetailsStep,
			minimisedUntilReached: true,
			hideSlide: !registrationEntity.parsedRegistrationData.organisation?.ppsrEnabled,
			sidebarSubtitle: 'PPSR',
			topBarLogo: PpsrLogo,
			topBarType: 'ppsr',
		},
		{
			name: 'Authorise applications',
			component: IntelDisclosingStep,
			minimisedUntilReached: true,
			hideSlide: !registrationEntity.parsedRegistrationData.organisation?.intelEnabled,
			sidebarSubtitle: 'Monitor',
			topBarLogo: MonitorLogo,
			topBarType: 'intel',
		},

		{
			name: 'Review terms & conditions',
			component: ReviewTermsStep,
			minimisedUntilReached: true,
			disableSave: true,
		},
		{
			name: 'Registration Submitted',
			component: RegistrationSubmittedStep,
			hideStepInSidebar: true,
			hideTopBar: true,
			hideSidebarComponents: true,
			disableSave: true,
		},
	];

	return (
		<div className="body-content organisation-registration-page">
			<Wizard
				stepInformation={wizardStepInformation}
				wizardName="Organisation Registration"
				model={registrationEntity.parsedRegistrationData}
				pageProperty="page"
				onCancel={() => store.routerHistory.push('/login')}
				onSaveDraft={async () => {
					await registrationEntity.save();
					await axios.post(
						`${SERVER_URL}/api/entity/RegistrationEntity/saveProgress`,
						{
							Id: registrationEntity.id,
							RegistrationType: 'Client',
						},
					);
					ShowProgressSavedModalPasscode(
						registrationEntity.userEmail,
					);
				}}
				onChangePage={async (pageIndex: number) => {
					// We don't want to save on every page changes because some pages
					// may not require this because they have unique logic.
					if (wizardStepInformation[pageIndex].disableSave) {
						return;
					}

					await registrationEntity.save();

					// If the registration email hasn't been sent then we send the
					// continue link and passcode to the given email
					if (!registrationEntity.parsedRegistrationData.continueEmailSent) {
						const result = await sendPasscodeEmail(registrationEntity.id);
						runInAction(() => {
							registrationEntity.referringOrganisationId = result.referringOrganisationId;
							registrationEntity.parsedRegistrationData.continueEmailSent = true;
						});
						store.routerHistory.push(`/hub/registration/${registrationEntity.id}`);
					}
				}}
				data={registrationEntity} // We pass in the registration entity so that attach files step can get the ID
			/>
			<div className="preload">
				<img src={PpsrLogo} alt="" />
				<img src={ApproveLogo} alt="" />
				<img src={MonitorLogo} alt="" />
			</div>
		</div>
	);
});

export default OrganisationRegistrationPage;
