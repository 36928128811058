import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { riskScore } from 'Models/Enums';
import If from 'Views/Components/If/If';
import { AtbDashboardContext } from 'Views/Pages/Monitor/MonitorDashboardPage';
import { useMemo } from 'react';

function DashboardAgedTrialBalanceSummary(props: {
	beIds: string[]
}) {
	const { beIds } = props;

	const atRiskBuckets = useMemo(() => ['HIGH', 'VERY_HIGH', 'SEVERE'] as riskScore[], []);
	const atRiskBucketMin = atRiskBuckets[0];
	const atRiskBucketMax = atRiskBuckets[atRiskBuckets.length - 1];

	const renderActionTile = React.useCallback((
		value: number,
		text: React.ReactNode,
		href: string,
		className: string,
		icon?: string,
	) => {
		return (
			<Link className={classNames('action rounded-box white', className)} to={href}>
				<div className="action-data">
					<If condition={value > 0}>
						<span className="tooltip icon-information-2 icon-right" />
					</If>
					<span className="number">{value}</span>
					{icon && <span className={`icon icon-only ${icon}`} />}
				</div>
				<div className="action-container">
					<div className="action-text">{text}</div>
					<div className="action-arrow">
						<span className="icon icon-only icon-arrow-right-2" />
					</div>
				</div>
			</Link>
		);
	}, []);

	function getCustomerListPageAdverseAlertsPath() {
		let url = '/monitor/customers?sort=adverseAlerts&dir=DESC';
		if (beIds.length > 0) {
			url += beIds.map(id => `&businessEntityIds=${id}`).join('');
		}
		return url;
	}
	function getCustomerListPageAtRiskPath() {
		let url = `/monitor/customers?riskBucket=${atRiskBucketMin}&riskBucket=${atRiskBucketMax}`;
		if (beIds.length > 0) {
			url += beIds.map(id => `&businessEntityIds=${id}`).join('');
		}
		return url;
	}

	return (
		<AtbDashboardContext.Consumer>
			{value => (
				<div className="dashboard-aged-trial-balance-summary flex-row">
					{renderActionTile(
						value.customersWithAdverseAlerts,
						(<>Customers with<br />Adverse Alerts</>),
						getCustomerListPageAdverseAlertsPath(),
						'white',
					)}
					{renderActionTile(
						value.customersAtRisk,
						(<>Customers<br />at Risk</>),
						getCustomerListPageAtRiskPath(),
						'white',
					)}
				</div>
			)}
		</AtbDashboardContext.Consumer>
	);
}

export default DashboardAgedTrialBalanceSummary;
