import {
	AddressEntity,
	ApplicationEntity,
	BusinessEntity,
	TrustEntityCodesEntity,
} from 'Models/Entities';
import { Button, Colors, Display } from '../Button/Button';
import { IconDisplay, IconTextBox } from '../TextBox/IconTextBox';
import _, { debounce } from 'lodash';
import { acnStatusOptions, acnTypeOptions } from '../../../Models/Enums';
import { action, runInAction } from 'mobx';
import { confirmModal, confirmModalAsync } from '../Modal/ModalUtils';
import { useEffect, useState } from 'react';

import { Checkbox } from '../Checkbox/Checkbox';
import { CheckboxGroup } from '../Checkbox/CheckboxGroup';
import { DatePicker } from '../DatePicker/DatePicker';
import EditApproveDetailsModal from './EditApproveDetailsModal';
import EditMonitorDetailsModal from './EditMonitorDetailsModal';
import EditPpsrDetailsModal from './EditPpsrDetailsModal';
import If from '../If/If';
import ProductSelectedBox from '../Clients/ProductSelectedBox';
import RadioButtonGroupSetter from '../RadioButton/RadioButtonGroupSetter';
import React from 'react';
import { SpgEntity } from '../../../Models/Entities';
import { TextArea } from '../TextArea/TextArea';
import { TextField } from 'Views/Components/TextBox/TextBox';
import alertToast from 'Util/ToastifyUtils';
import { observer } from 'mobx-react';
import { store } from '../../../Models/Store';
import useAsync from 'Hooks/useAsync';

// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface BusinessEntityFieldsProps {
	isPrimary: boolean,
	organisationEmail?: string,
	businessEntity: BusinessEntity,
	originalBusinessEntity: BusinessEntity,
	disabled?: boolean,
	addNew?: boolean,
	onAfterChange?: (validateSuccessful: boolean) => void,
	// to open an 'add product' modal automatically
	openProductModal?: ('add-ppsr' | 'add-approve' | 'add-intel' |'none'),
}

const BusinessEntityFields: React.FunctionComponent<BusinessEntityFieldsProps> = observer(props => {
	const {
		businessEntity, originalBusinessEntity, organisationEmail, isPrimary, disabled,
		onAfterChange, openProductModal,
	} = props;

	const addNew = props.addNew ?? false;
	// state to determine if a modal is open
	const [modalState, setModalState] = useState(openProductModal || 'none' as ('add-ppsr' | 'add-approve' | 'add-intel' |'none'));

	useEffect(() => {
		if (!businessEntity.address) {
			runInAction(() => {
				businessEntity.address = new AddressEntity();
			});
		}
	}, [businessEntity]);

	// used for the radio button and determining trusts
	const [trusteeHasACN, setTrusteeHasACN] = React.useState(() => {
		if (businessEntity.trusteeACN) {
			return 'Yes';
		}

		if (businessEntity.trusteeName) {
			return 'No';
		}
		return '';
	});

	useAsync(async () => {
		const validationPromises = [];
		if (businessEntity.abn) {
			validationPromises.push(businessEntity.validateField('abn'));
		}

		if (businessEntity.acn) {
			validationPromises.push(businessEntity.validateField('acn'));
		}

		if (businessEntity.trusteeACN) {
			validationPromises.push(businessEntity.validateField('trusteeACN'));
		}
		return Promise.all(validationPromises);
	}, [businessEntity]);

	/**
	 * Handle for the change event for the inputs. This method will attempt to validate a given field
	 * iff there are existing errors. So that the user can immediately see when their input stops the error
	 * @param field The name of the field to be validated
	 * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
	 * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
	 */
	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!businessEntity.errors[field]) {
			await businessEntity.validateField(field);
		}

		if (!!onAfterChange) {
			const validateSuccessful = Object.keys(businessEntity.errors).length <= 0;
			onAfterChange(validateSuccessful);
		}
	};

	const gridTemplate = `'${[
		/* eslint-disable no-tabs */
		['name', 'phone', 'products'],
		['abn', 'email', 'product-buttons'],
		['acn', 'address1', '.'],
		['trusteedob', 'address2', '.'],
		['acnother', 'suburb', '.'],
		['ppsrcheckboxes', 'statepostcode', '.'],
		['ppsrsummary', 'country', '.'],
		/* eslint-enable no-tabs */
	].map(l => l.join(' ')).join("' '")}'`;

	async function removeProduct(product: 'PPSR' | 'Approve' | 'Monitor') {
		const modal = confirmModalAsync(
			'Remove Product?',
			(
				<p>
					Continuing with this action will deactivate the product from your list and mean you no longer have access to the features.
				</p>
			),
			{ confirmText: 'Yes, remove' },
		);

		try {
			await modal.result();
		} catch (e) {
			return;
		}

		try {
			switch (product) {
				case 'Approve':
					await businessEntity.removeApproveProduct();
					await businessEntity.save();
					break;
				case 'PPSR':
					await businessEntity.removePpsrProduct();
					await businessEntity.save();
					break;
				case 'Monitor':
					await businessEntity.removeMonitorProduct();
					break;
			}
			alertToast(`Access ${product} successfully removed`, 'success');
		} catch (exception) {
			alertToast(`Access ${product} could not be removed, please refresh and try again`, 'error');
		} finally {
			modal.close();
		}
	}

	return (
		<div className="business-entity-fields" style={{ gridTemplateAreas: gridTemplate }}>
			<div style={{ gridArea: 'name' }}>
				<TextField
					className="business-name"
					model={businessEntity}
					modelProperty="name"
					label=" Name"
					placeholder="Name"
					onChangeAndBlur={async () => { await onChange('name', true); }}
					onAfterChange={async () => { await onChange('name'); }}
					errors={businessEntity.errors.name}
					isRequired
					isDisabled={disabled}
				/>
			</div>
			<div style={{ gridArea: 'phone' }}>
				<TextField
					className="phone"
					model={businessEntity}
					modelProperty="phone"
					label="Phone number"
					placeholder="Phone number"
					onChangeAndBlur={async () => { await onChange('phone', true); }}
					onAfterChange={async () => { await onChange('phone'); }}
					errors={businessEntity.errors.phone}
					isDisabled={disabled}
				/>
			</div>
			<If condition={!addNew}>
				<div style={{ gridArea: 'products' }} className="products">
					<b>Products</b>
					<ProductSelectedBox
						approveEnabled={businessEntity.enabledForApprove}
						ppsrEnabled={businessEntity.enabledForPPSR}
						intelEnabled={businessEntity.enabledForMonitor}
						greyOutOnDisable
						hideNoneSelected
					/>
				</div>
				<div style={{ gridArea: 'product-buttons' }} className="add-products-container">
					{/* we should only show the product buttons if the organisation has them enabled and
					the user has access and the business entity doesn't have access and the user can edit the
					business entity */}
					<If condition={
						(businessEntity.organisation?.approveEnabled
						|| businessEntity.primaryOrganisation?.approveEnabled)
						&& store.getUser?.canAccessApprove
						&& !businessEntity.enabledForApprove
						&& !disabled
						&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
					}
					>
						<Button
							className="add-approve icon-plus icon-left"
							display={Display.OutlineDashed}
							colors={Colors.Alternate}
							onClick={() => setModalState('add-approve')}
						>
							Enable Approve Product
						</Button>
					</If>
					<If condition={
						(businessEntity.organisation?.approveEnabled
							|| businessEntity.primaryOrganisation?.approveEnabled)
						&& store.getUser?.canAccessApprove
						&& businessEntity.enabledForApprove
						&& store.userType === 'SUPER_USER'
						&& !disabled
						&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
					}
					>
						<Button
							className="add-approve icon-minus icon-left"
							display={Display.OutlineDashed}
							colors={Colors.Alternate}
							onClick={() => removeProduct('Approve')}
						>
							Remove Approve Product
						</Button>
					</If>
					<If condition={
						(businessEntity.organisation?.ppsrEnabled
							|| businessEntity.primaryOrganisation?.ppsrEnabled)
						&& store.getUser?.canAccessPPSR
						&& !businessEntity.enabledForPPSR
						&& !disabled
						&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
					}
					>
						<Button
							className="add-ppsr icon-plus icon-left"
							display={Display.OutlineDashed}
							colors={Colors.Alternate}
							onClick={() => setModalState('add-ppsr')}
						>
							Enable PPSR Product
						</Button>
					</If>
					<If condition={
						(businessEntity.organisation?.ppsrEnabled
							|| businessEntity.primaryOrganisation?.ppsrEnabled)
						&& store.getUser?.canAccessPPSR
						&& businessEntity.enabledForPPSR
						&& store.userType === 'SUPER_USER'
						&& !disabled
						&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
					}
					>
						<Button
							className="add-ppsr icon-minus icon-left"
							display={Display.OutlineDashed}
							colors={Colors.Alternate}
							onClick={() => removeProduct('PPSR')}
						>
							Remove PPSR Product
						</Button>
					</If>
					<If condition={
						(businessEntity.organisation?.intelEnabled
							|| businessEntity.primaryOrganisation?.intelEnabled)
						&& store.getUser?.canAccessIntel
						&& !businessEntity.enabledForMonitor
						&& !disabled
						&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
					}
					>
						<Button
							className="add-monitor icon-plus icon-left"
							display={Display.OutlineDashed}
							colors={Colors.Alternate}
							onClick={() => setModalState('add-intel')}
						>
							Enable Monitor Product
						</Button>
					</If>
					<If condition={
						(businessEntity.organisation?.intelEnabled
							|| businessEntity.primaryOrganisation?.intelEnabled)
						&& store.getUser?.canAccessIntel
						&& businessEntity.enabledForMonitor
						&& store.userType === 'SUPER_USER'
						&& !disabled
						&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
					}
					>
						<Button
							className="add-monitor icon-minus icon-left"
							display={Display.OutlineDashed}
							colors={Colors.Alternate}
							onClick={() => removeProduct('Monitor')}
						>
							Remove Monitor Product
						</Button>
					</If>
				</div>
			</If>
			<div style={{ gridArea: 'abn' }}>
				<IconTextBox
					className="business-number abn"
					model={businessEntity}
					modelProperty="abn"
					label="ABN"
					placeholder="ABN"
					onChangeAndBlur={debounce(async () => {
						await onChange('abn', true);
					}, 300)}
					onAfterChange={debounce(async () => {
						await onChange('abn');
					}, 300)}
					errors={businessEntity.errors.abn}
					isRequired
					displayIconContainer={businessEntity.abnFinishedValidating}
					iconDisplay={businessEntity.entityStatus !== '' && businessEntity.entityStatus === 'Active'
						? IconDisplay.Valid : IconDisplay.Error}
					isDisabled={disabled}
				/>
				<small className="field-details">
					<b>Status:</b> { businessEntity.entityStatus }<br />
					<b>Type:</b> { businessEntity.entityTypeDescription }<br />
					<b>Entity Name:</b> { businessEntity.entityName }
				</small>
			</div>
			<div style={{ gridArea: 'email' }}>
				{/* If user selects use organisation email, we disable the input and use primary business entity value */}
				<TextField
					className="email"
					model={businessEntity.useOrganisationEmail ? { email: organisationEmail } : businessEntity}
					modelProperty="email"
					label="Email"
					placeholder="Email"
					onChangeAndBlur={() => onChange('email', true)}
					onAfterChange={() => onChange('email')}
					errors={businessEntity.errors.email}
					isRequired
					isDisabled={disabled || businessEntity.useOrganisationEmail}
				/>

				{/* If we aren't the primary business entity render checkbox */}
				<If condition={!isPrimary}>
					<Checkbox
						model={businessEntity}
						modelProperty="useOrganisationEmail"
						label="Same as Organisation email"
						className="business-entity-org-email-checkbox"
						onAfterChecked={action((event, checked) => {
							if (checked) {
								businessEntity.email = '';
								delete businessEntity.errors.email;
							}
							if (onAfterChange) {
								onAfterChange(true);
							}
						})}
						isDisabled={disabled}
					/>
				</If>
			</div>
			<div style={{ gridArea: 'acn' }}>
				<IconTextBox
					className="business-number acn"
					model={businessEntity}
					modelProperty="acn"
					label="ACN"
					placeholder="ACN"
					onChangeAndBlur={async () => {
						await onChange('acn', true);
					}}
					onAfterChange={debounce(async () => {
						await onChange('acn');
					}, 300)}
					errors={businessEntity.errors.acn}
					displayIconContainer={businessEntity.acnFinishedValidating}
					iconDisplay={businessEntity.acnStatus !== '' && businessEntity.acnStatus === 'REGD'
						? IconDisplay.Valid : IconDisplay.Error}
					isDisabled={disabled}
				/>
				<small className="field-details">
					<b>Status:</b> { acnStatusOptions[businessEntity.acnStatus] }<br />
					<b>Type:</b> { acnTypeOptions[businessEntity.acnEntityTypeCode] }<br />
					<b>Entity Name:</b> { businessEntity.acnEntityName }
				</small>
			</div>
			{/* if the entity typecode is a trust show the radio button */}
			<If condition={businessEntity.entityTypeCode !== ''
				&& TrustEntityCodesEntity.trusteeCodesSync.includes(businessEntity.entityTypeCode)}
			>
				<div style={{ gridArea: 'trusteedob' }}>
					<RadioButtonGroupSetter
						className="trusteeRadio"
						value={trusteeHasACN}
						setValue={setTrusteeHasACN}
						options={[
							{ value: 'Yes', display: 'Yes, I have an ACN' },
							{ value: 'No', display: 'No' },
						]}
						onAfterSet={value => {
							runInAction(() => {
								if (value === 'Yes') {
									businessEntity.useCorporateTrusteeACN = value;
									businessEntity.trusteeName = '';
								} else if (value === 'No') {
									businessEntity.useCorporateTrusteeACN = value;
									businessEntity.trusteeACN = '';
									businessEntity.trusteeACNStatus = '';
									// @ts-ignore
									businessEntity.abrLastUpdated = undefined;
									businessEntity.trusteeAcnFinishedValidating = false;
								}
							});
						}}
						label="Do you have a trustee Australian Company Number (ACN)?"
						// the error on this radio button is if the user has pressed next before choosing an
						// option. so both trusteeName and trustee acn are empty meaning this radio button has an error
						errors={(businessEntity.errors.trusteeName && businessEntity.errors.trusteeACN
						&& !trusteeHasACN ? 'An option must be selected' : undefined)}
						isRequired
						isDisabled={disabled}
					/>
				</div>
				{/* display the ACN input if the radio button was yes */}
				<If condition={trusteeHasACN === 'Yes'}>
					<div style={{ gridArea: 'acnother' }}>
						<IconTextBox
							className="business-number trusteeACN"
							model={businessEntity}
							modelProperty="trusteeACN"
							label="Trustee ACN"
							placeholder="Trustee ACN"
							onChangeAndBlur={action(async () => {
								await onChange('trusteeACN', true);
								runInAction(() => {
									delete businessEntity.errors.trusteeName;
								});
							})}
							onAfterChange={debounce(action(async () => {
								await onChange('trusteeACN');
								runInAction(() => {
									delete businessEntity.errors.trusteeName;
								});
							}), 300)}
							errors={businessEntity.errors.trusteeACN}
							isRequired
							displayIconContainer={businessEntity.trusteeAcnFinishedValidating && !businessEntity.errors.trusteeACN}
							iconDisplay={businessEntity.trusteeACNStatus !== '' && businessEntity.trusteeACNStatus === 'REGD'
								? IconDisplay.Valid : IconDisplay.Error}
							isDisabled={disabled}
						/>
						<small className="field-details">
							<b>Status:</b> { acnStatusOptions[businessEntity.trusteeACNStatus] }<br />
							<b>Type:</b> { acnTypeOptions[businessEntity.trusteeACNEntityTypeCode] }<br />
							<b>Entity Name:</b> { businessEntity.trusteeACNEntityName }
						</small>
					</div>
				</If>

				{/* display the trustee name input if the radio button was no */}
				<If condition={trusteeHasACN === 'No'}>
					<div style={{ gridArea: 'acnother' }}>
						<TextField
							className="trustee-name"
							model={businessEntity}
							modelProperty="trusteeName"
							label="Trustee name"
							placeholder="Trustee name"
							onChangeAndBlur={action(async () => {
								await onChange('trusteeName', true);
								runInAction(() => {
									delete businessEntity.errors.trusteeACN;
								});
							})}
							onAfterChange={action(async () => {
								await onChange('trusteeName');
								runInAction(() => {
									delete businessEntity.errors.trusteeACN;
								});
							})}
							errors={businessEntity.errors.trusteeName}
							isRequired
							isDisabled={disabled}
						/>
					</div>
				</If>
			</If>
			{/* if the entity typecode is an individual show the date of birth */}
			<If condition={businessEntity.entityTypeCode !== '' && businessEntity.entityTypeCode === 'IND'}>
				<div style={{ gridArea: 'trusteedob' }}>
					<DatePicker
						model={businessEntity}
						modelProperty="dateOfBirth"
						label="Sole trader's date of birth"
						placeholder="Date of birth"
						onChangeAndBlur={() => onChange('dob', true)}
						onAfterChange={() => onChange('dob', true)}
						errors={businessEntity.errors.dob}
						isRequired
						isDisabled={disabled}
					/>
				</div>
			</If>
			<If condition={!!businessEntity.address}>
				<div style={{ gridArea: 'address1' }}>
					<TextField
						className="line-1"
						label="Address 1"
						placeholder="Address 1"
						model={businessEntity.address}
						modelProperty="line1"
						isDisabled={disabled}
						onChangeAndBlur={() => onChange('address.line1', true)}
						onAfterChange={() => onChange('address.line1')}
					/>
				</div>
				<div style={{ gridArea: 'address2' }}>
					<TextField
						className="line-2"
						label="Address 2"
						placeholder="Address 2"
						model={businessEntity.address}
						modelProperty="line2"
						isDisabled={disabled}
						onChangeAndBlur={() => onChange('address.line2', true)}
						onAfterChange={() => onChange('address.line2')}
					/>
				</div>
				<If condition={businessEntity.enabledForPPSR}>
					<div style={{ gridArea: 'ppsrcheckboxes' }}>
						<CheckboxGroup
							label="Type of business?"
							className="newclass"
						>
							<Checkbox
								model={businessEntity}
								modelProperty="hire"
								label="Hire"
								className="hire-input-group__checkbox"
								isDisabled={(store.userType === 'USER')}
								onChecked={(event, checked) => {
									businessEntity.hire = checked;
									onChange('hire');
								}}
							/>
							<Checkbox
								model={businessEntity}
								modelProperty="supplyGnE"
								className="supplyGnE-input-group__checkbox"
								label="Goods & Equipment"
								isDisabled={(store.userType === 'USER')}
								onChecked={(event, checked) => {
									businessEntity.supplyGnE = checked;
									onChange('supplyGnE');
								}}
							/>
							<Checkbox
								model={businessEntity}
								modelProperty="finance"
								className="finance-input-group__checkbox"
								label="Finance"
								isDisabled={(store.userType === 'USER')}
								onChecked={(event, checked) => {
									businessEntity.finance = checked;
									onChange('finance');
								}}
							/>
						</CheckboxGroup>
					</div>
				</If>
				<div style={{ gridArea: 'suburb' }}>
					<TextField
						className="suburb"
						label="Suburb"
						placeholder="Suburb"
						model={businessEntity.address}
						modelProperty="suburb"
						isDisabled={disabled}
						onChangeAndBlur={() => onChange('address.suburb', true)}
						onAfterChange={() => onChange('address.suburb')}
					/>
				</div>
				<If condition={businessEntity.enabledForPPSR}>
					<div style={{ gridArea: 'ppsrsummary' }}>
						<TextArea
							model={businessEntity}
							className="summary"
							modelProperty="summary"
							label="Summary of your business"
							labelVisible
							placeholder="Summary of your business"
							onChangeAndBlur={async () => {
								await onChange('summary', true);
							}}
							onAfterChange={async () => {
								await onChange('summary');
							}}
							isDisabled={(store.userType === 'USER')}
							errors={businessEntity.errors.summary}
						/>
					</div>
				</If>
				<div className="input-group-statepostcode" style={{ gridArea: 'statepostcode' }}>
					<TextField
						className="state"
						label="State"
						placeholder="State"
						model={businessEntity.address}
						modelProperty="state"
						isDisabled={disabled}
						onChangeAndBlur={() => onChange('address.state', true)}
						onAfterChange={() => onChange('address.state')}
					/>
					<TextField
						className="postcode half-width"
						label="Postcode"
						placeholder="Postcode"
						model={businessEntity.address}
						modelProperty="postcode"
						errors={businessEntity.errors['address.postcode']}
						onChangeAndBlur={() => onChange('address.postcode', true)}
						onAfterChange={() => onChange('address.postcode')}
						isDisabled={disabled}
					/>
				</div>
				<div style={{ gridArea: 'country' }}>
					<TextField
						className="country half-width"
						label="Country"
						placeholder="Country"
						model={businessEntity.address}
						modelProperty="country"
						onChangeAndBlur={async () => {
							await onChange('country', true);
						}}
						onAfterChange={async () => {
							await onChange('country');
						}}
						isDisabled={disabled}
					/>
				</div>
			</If>
			<If condition={modalState === 'add-approve'}>
				<EditApproveDetailsModal
					businessEntity={businessEntity}
					onClose={() => setModalState('none')}
					onSubmit={async () => {
						try {
							const savedEntity = await businessEntity.saveWithFetchBack({
								applicationss: {},
							}, {
								contentType: 'multipart/form-data',
							}, `
								applicationss {
									${ApplicationEntity.getAttributes().join('\n')}
									${ApplicationEntity.getFiles().map(f => f.name).join('\n')}
								}
							`);
							// we set back the original validation information as this won't change because of the save
							// and to stop the fields from invalidating in the background
							// we also save back the reset the applicationss so the list isn't appended
							originalBusinessEntity.enabledForApprove = savedEntity.enabledForApprove;
							originalBusinessEntity.applicationss = [...savedEntity.applicationss];
							setModalState('none');
							alertToast('Access Approve successfully enabled', 'success');
						} catch (exception) {
							alertToast('Access Approve could not be enabled, please refresh and try again', 'error');
						}
					}}
					addNew
				/>
			</If>
			<If condition={modalState === 'add-ppsr'}>
				<EditPpsrDetailsModal
					businessEntity={businessEntity}
					onClose={() => setModalState('none')}
					onSubmit={async () => {
						try {
							const savedEntity = await businessEntity.saveWithFetchBack({
								spgss: {},
							}, {}, `
								spgss {
									${SpgEntity.getAttributes().join('\n')}
								}
							`);
							// we set back the original validation information as this won't change because of the save
							// and to stop the fields from invalidating in the background
							// we also save back the reset the spgss so the list isn't appended
							originalBusinessEntity.hire = savedEntity.hire;
							originalBusinessEntity.supplyGnE = savedEntity.supplyGnE;
							originalBusinessEntity.finance = savedEntity.finance;
							originalBusinessEntity.summary = savedEntity.summary;
							originalBusinessEntity.enabledForPPSR = savedEntity.enabledForPPSR;
							originalBusinessEntity.spgss = [...savedEntity.spgss];
							setModalState('none');
							alertToast('Access PPSR successfully enabled', 'success');
						} catch (exception) {
							alertToast('Access PPSR could not be enabled, please refresh and try again', 'error');
						}
					}}
					addNew
				/>
			</If>
			<If condition={modalState === 'add-intel'}>
				<EditMonitorDetailsModal
					businessEntity={businessEntity}
					onClose={() => setModalState('none')}
					onSubmit={async () => {
						try {
							const savedEntity = await businessEntity.saveWithFetchBack({
								monitorNotificationsEmail: {},
								enableEmailNotifications: {},
							}, {}, `
							`);

							originalBusinessEntity.enabledForMonitor = savedEntity.enabledForMonitor;
							originalBusinessEntity.monitorNotificationsEmail = savedEntity.monitorNotificationsEmail;
							originalBusinessEntity.enableEmailNotifications = savedEntity.enableEmailNotifications;
							setModalState('none');
							alertToast('Access Monitor successfully enabled', 'success');
						} catch (exception) {
							alertToast('Access Monitor could not be enabled, please refresh and try again', 'error');
						}
					}}
					addNew
				/>
			</If>
		</div>
	);
});

export default BusinessEntityFields;
