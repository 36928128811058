import * as React from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { useState, createRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import WizardProgressSummary from 'Views/Components/Wizard/WizardProgressSummary';
import WizardTopBar from 'Views/Components/Wizard/WizardTopBar';

export interface WizardComponentProps {
	currentStep: WizardStepInformation;
	model: any;
	data?: any; // Used to pass in any extra data needed
	changePage: (forward?: boolean, pageIndex?: number) => void;
	alignmentBoxRef?: React.RefObject<HTMLDivElement>;
}

export interface WizardSidebarComponentProps {
	model: any;
	currentSlide: number;
	stepInformation: WizardStepInformation[];
	changePage: (forward?: boolean, pageIndex?: number) => void;
}

export interface WizardStepInformation {
	name: string;
	component: React.FunctionComponent<WizardComponentProps>;
	sidebarComponent?: React.FunctionComponent<WizardSidebarComponentProps>;
	topBarLogo?: string;
	topBarType?: string;
	topBarTitle?: string;
	minimisedUntilReached?: boolean;
	hideSlide?: boolean;
	hideStepInSidebar?: boolean;
	sidebarSubtitle?: string;
	hideTopBar?: boolean;
	hideSidebarComponents?: boolean;
	disableSaveProgress?: boolean;
	disableSave?: boolean;
	options?: any;
}

export interface WizardProps {
	stepInformation: WizardStepInformation[];
	wizardName: string;
	model: any; // We expect this object to be an observable
	pageProperty?: string; // If set, we use this value to determine our current page, rather than using an internal variable
	onCancel: () => void;
	onSaveDraft: () => void;
	onChangePage: (pageIndex: number) => void;
	data?: any; // Used to pass in any extra data needed
}

const Wizard = observer((props: WizardProps) => {
	const {
		stepInformation,
		model,
		pageProperty,
		onCancel,
		onSaveDraft,
		onChangePage,
		data,
	} = props;

	const [internalPage, setInternalPage] = useState(0);

	const page = !!pageProperty ? model[pageProperty] ?? 0 : internalPage;
	const setPage = !!pageProperty ? action((value: number) => {
		model[pageProperty] = value;
	})
		: setInternalPage;

	const currentStep = stepInformation[page];
	const CurrentStepComponent = currentStep.component;
	const SidebarComponent = currentStep.sidebarComponent ?? WizardProgressSummary;

	// Some pages are not visible in the sidebar. In those cases, we instead show the last visible slide
	const sidebarIndex = !currentStep.hideStepInSidebar
		? page
		: stepInformation
			.map((step, index) => !step.hideStepInSidebar && !step.hideSlide && index < page)
			.lastIndexOf(true);

	// Move either forward or backward a page. If we pass a specific pageIndex, we jump directly to that page instead
	// In that case, we ignore the forward value, which determines whether to move forward or backward one page.
	// eslint-disable-next-line default-param-last
	const changePage = (forward: boolean = true, pageIndex?: number) => {
		// If we received a page index, we go directly to that page
		if (pageIndex !== undefined) {
			if (stepInformation.length > pageIndex && !stepInformation[pageIndex].hideSlide) {
				setPage(pageIndex);
				onChangePage(pageIndex);
			}
			return;
		}

		// Otherwise we go forward or backward one page (skipping any hidden pages)
		let newPage = page;

		// Keep incrementing/decrementing page, until we've found a valid page to move to, or we're out of bounds
		do {
			newPage += forward ? 1 : -1;
		} while (newPage >= 0 && newPage < stepInformation.length && stepInformation[newPage].hideSlide === true);

		// Jump to the new page, but only if it's valid
		if (newPage >= 0 && newPage < stepInformation.length) {
			setPage(newPage);
			onChangePage(newPage);
		}

		// If new page is prior to the beginning of the wizard (ie we pressed Back on the first slide) then we leave instead
		if (newPage < 0) {
			onCancel();
		}
	};

	const [windowSize, setWindowSize] = useState(getWindowSize());
	const [newClass, setNewClass] = useState('');
	const divRef = createRef<HTMLDivElement>();

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	// this is to add a drop shadow class to the button container if it's overlapping content
	useEffect(() => {
		if (divRef.current) {
			const boundingHeight = divRef.current.getBoundingClientRect().height;
			// set the below height to the window height
			if (boundingHeight > window.innerHeight - 220) {
				setNewClass('dropshadow');
			} else {
				setNewClass('');
			}
		}
	}, [divRef, windowSize]);

	return (
		<div className="wizard">
			<div className="wizard-content">
				{currentStep.hideTopBar ? (<div />) : (
					<WizardTopBar
						currentStep={currentStep}
						key={currentStep.topBarType ?? 'access-group'}
					/>
				)}
				<div className={`step-area ${newClass}`}>
					<CurrentStepComponent
						currentStep={currentStep}
						model={model}
						changePage={changePage}
						data={data}
						alignmentBoxRef={divRef}
					/>
				</div>
			</div>
			<div className="wizard-sidebar gradient">
				<div className="pattern" />

				{currentStep.hideSidebarComponents ? (<div />) : (
					<>
						<div className="button-container">
							<Button
								className="cancel-btn"
								colors={Colors.Primary}
								display={Display.Outline}
								onClick={async () => {
									if (!!onCancel) {
										await onCancel();
									}
								}}
							>
								Cancel
							</Button>
							<Button
								className="save-progress-btn"
								colors={Colors.Primary}
								display={Display.Outline}
								disabled={currentStep.disableSaveProgress}
								onClick={async () => {
									if (!!onSaveDraft) {
										await onSaveDraft();
									}
								}}
							>
								Save Progress
							</Button>
						</div>

						<SidebarComponent
							model={model}
							currentSlide={sidebarIndex}
							stepInformation={stepInformation}
							changePage={changePage}
						/>
					</>
				)}
			</div>
		</div>
	);
});
export default Wizard;
