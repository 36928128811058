import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import RegistrationSubmitted from 'Assets/Animations/registration-submitted.json';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import LottieIcon from 'Views/Components/Animations/LottieIcon';
import useAsync from 'Hooks/useAsync';
import RegistrationData from 'Models/Entities/RegistrationData';
import { useEffect } from 'react';
import alertToast from 'Util/ToastifyUtils';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import axios from 'axios';
import { SERVER_URL } from '../../../Constants';

const ProductSetupCompletedStep = observer((props: WizardComponentProps) => {
	const { currentStep, changePage } = props;
	const { model }: { model: RegistrationData } = props;

	const completionResult = useAsync(async () => {
		await model.organisation?.save({ authorisedCreditBureaus: {} });

		const savePromises = [];
		if (currentStep.options.updatedBilling) {
			savePromises.push(axios.post(`${SERVER_URL}/api/entity/RegistrationEntity/update-organisation-details`, {
				organisationId: model.organisation?.id,
				customerCount: model.activeCustomers,
				monthlyExposureValue: model.monthlyExposure,
				industryCodeId: model.industryCode?.id,
			}));
		}
		if (model.businessEntitys) {
			for (const businessEntity of model.businessEntitys) {
				const relationPath: { [key: string]: any } = {};
				const options: { [key: string]: any } = {};
				if (businessEntity.enabledForPPSR && !!currentStep.options?.ppsr) {
					relationPath.spgss = {};
				}
				if (businessEntity.enabledForApprove && !!currentStep.options?.approve) {
					relationPath.applicationss = {};
					options.contentType = 'multipart/form-data';
				}
				savePromises.push(businessEntity.save(relationPath, options));
			}
		}
		await Promise.all(savePromises);
	}, []);

	useEffect(() => {
		if (completionResult.type !== 'error') {
			return;
		}

		alertToast('There was an issue completing the setup for the new products. Please try again, or contact'
			+ ' Access Intell for help.', 'error');
		changePage(false);
	}, [changePage, completionResult]);

	if (completionResult.type !== 'data') {
		// If the setup failed, we still show this until the useEffect above redirects them to the previous page
		return (
			<div className="step-wrap registration-submitted-step">
				<InlineSpinner />
			</div>
		);
	}

	return (
		<div className="step-wrap registration-submitted-step">
			<div className="step-contents registration-step align-one-third">
				<div className="alignment-box">
					<div className="submit-icon-container">
						<LottieIcon autoplay width={100} height={100} animationData={RegistrationSubmitted} />
					</div>
					<div className="step-header">
						<h5>
							Product application completed, you can now access the new
							product{!!currentStep.options?.setupMultipleProducts ? 's' : ''}
						</h5>
					</div>
					<div>
						<p>You are able to view the products you have access to in the organisation page</p>
					</div>
					<div>
						<Button
							display={Display.Solid}
							colors={Colors.Secondary}
							onClick={() => {
								store.routerHistory.goBack();
								axios.get(`${SERVER_URL}/api/account/me`)
									.then(({ data }) => store.setLoggedInUser(data))
									.catch(() => {});
							}}
							className="return-to-login"
						>
							Return to organisation page
						</Button>
					</div>
				</div>
			</div>
			<div className="questions-container">
				<p>If there are any questions, contact support@accessintell.com</p>
			</div>
		</div>
	);
});

export default ProductSetupCompletedStep;
