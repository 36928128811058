import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import {
	CUSTOMER_AUDITS_PAGE_LENGTH,
	CustomerAuditDescription,
	CustomerAuditEntityResponse,
} from './Gibs/FetchCustomerAudits';
import RequestWrap from 'Views/Components/RequestWrap/RequestWrap';

import EntityList from 'Views/Components/EntityList/EntityList';
import If from 'Views/Components/If/If';
import React from 'react';
import Spinner from 'Views/Components/Spinner/Spinner';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import {
	customerAuditCategory,
	customerAuditCategoryOptions,
} from 'Models/Enums';
import moment from 'moment';
import CustomerAuditDescriptionModal from './Gibs/CustomerAuditDescriptionModal';

interface Props {
	page: number;
	count: number;
	refreshKey: string;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	fetchCustomerAudits: () => Promise<CustomerAuditEntityResponse[]>;
}

const CustomerAuditsTable: React.FunctionComponent<Props> = ({
	page,
	count,
	setPage,
	fetchCustomerAudits,
	refreshKey,
}) => {
	const [showUpdateDetailsNote, setShowUpdateDetailsNote] = React.useState(false);
	const [selectedUpdateDetails, setSelectedUpdateDetails] = React.useState<{
		description: CustomerAuditDescription,
		category?: customerAuditCategory,
	}>({ description: { title: '' } });

	const renderUpdateDetailsColumn = (customerAudit: CustomerAuditEntityResponse) => {
		const { description, category } = customerAudit;

		if (!description.message) {
			return description.title;
		}

		return (
			<div>
				{description.title}
				<Button
					type="button"
					display={Display.Text}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
					icon={{ icon: 'bookmark', iconPos: 'icon-left' }}
					onClick={() => {
						setShowUpdateDetailsNote(true);
						setSelectedUpdateDetails({ description, category });
					}}
					labelVisible={false}
				/>
			</div>
		);
	};

	return (
		<div className="collection-component">
			<RequestWrap
				refreshKey={refreshKey}
				request={fetchCustomerAudits}
				loadingContent={<Spinner />}
				errorContent={(
					<div className="error-container">
						<h4>
							Failed to retrieve the latest customer Updates!. Please refresh the browser
						</h4>
					</div>
				)}
			>
				{(customerAudits: CustomerAuditEntityResponse[]) => (
					<>
						<If condition={customerAudits.length === 0}>
							<div className="error-container">
								<h4>No customer updates found</h4>
							</div>
						</If>
						<If condition={customerAudits.length > 0}>
							<EntityList
								collection={customerAudits}
								columns={[
									{
										displayName: 'Date',
										columnName: 'created',
										value: (item: CustomerAuditEntityResponse) => (
											moment(item.created).format('DD/MM/YYYY')
										),
									},
									{
										displayName: 'Time',
										columnName: 'time',
										value: (item: CustomerAuditEntityResponse) => (
											moment(item.created).format('HH:mm')
										),
									},
									{
										displayName: 'Update Type',
										columnName: 'category',
										value: (item: CustomerAuditEntityResponse) => (
											customerAuditCategoryOptions[item.category]
										),
									},
									{
										displayName: 'Update Details',
										columnName: 'description',
										value:
										(item: CustomerAuditEntityResponse) => renderUpdateDetailsColumn(item),
									},
									{
										displayName: 'Updated By',
										columnName: 'updatedBy',
										value: (item: CustomerAuditEntityResponse) => (
											item.user
												? `${item.user.firstName} ${item.user.lastName}`
												: 'Access Intell System'
										),
									},
								]}
								idColumn="id"
								sortColumn="created"
								sortDescending
							/>
							<section className="collection_load">
								<TablePagination
									perPage={CUSTOMER_AUDITS_PAGE_LENGTH}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</If>

						{/* render update details (customer description) notes modal. */}
						<CustomerAuditDescriptionModal
							initialDescription={selectedUpdateDetails.description}
							customerId=""
							openModal={showUpdateDetailsNote}
							closeModal={() => {
								setShowUpdateDetailsNote(false);
								setSelectedUpdateDetails({ description: { title: '', message: undefined } });
							}}
							category={selectedUpdateDetails.category ?? 'OTHER'}
						/>
					</>
				)}
			</RequestWrap>
		</div>
	);
};

export default CustomerAuditsTable;
