import * as React from 'react';
import { observer } from 'mobx-react';
import OrganisationFields from 'Views/Components/Organisation/OrganisationFields';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';

const OrganisationDetailsStep = observer((props: WizardComponentProps) => {
	const { model, changePage } = props;

	const onNext = async () => {
		await model.businessEntitys[0].validateDetailsFields();
		if (Object.keys(model.businessEntitys[0].errors).length === 0) {
			changePage();
		}
	};

	return (
		<div className="step-wrap">
			<div className="step-contents registration-step organisation-details-step align-one-third">
				<div className="alignment-box" ref={props.alignmentBoxRef}>
					<h5 className="step-header">Your organisation&apos;s details</h5>
					<div>
						<OrganisationFields
							businessEntity={model.businessEntitys[0]}
							type="organisation"
						/>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<Button
					className="right"
					colors={Colors.Primary}
					display={Display.Solid}
					disabled={Object.keys(model.businessEntitys[0].errors).length > 0}
					onClick={onNext}
				>
					Next
				</Button>
			</div>
		</div>
	);
});
export default OrganisationDetailsStep;
