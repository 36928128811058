import * as React from 'react';
import { observer } from 'mobx-react';
import AccountFields, { AccountFieldValidationFunctions } from 'Views/Components/Account/AccountFields';
import { Errors } from 'Util/CustomTypes';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { useState } from 'react';
import { runInAction } from 'mobx';

export const validateAccountDetailsStep = async (
	model: any,
	errors: Errors,
	setErrors?: React.Dispatch<React.SetStateAction<Errors>>,
) => {
	Object.keys(AccountFieldValidationFunctions).forEach(field => {
		const error = AccountFieldValidationFunctions[field](model.user);
		if (field === 'organisation' || field === 'userType' || field === 'businessEntity' || !error) {
			delete errors[field];
		} else {
			errors[field] = error;
		}
	});

	// disable save if there are errors
	const errorFound = Object.keys(errors).length > 0;
	if (!!setErrors) {
		setErrors({ ...errors }); // Clone the object to that React recognises the change
	}
	return !errorFound;
};

const AccountDetailsStep = observer((props: WizardComponentProps) => {
	const { model, changePage } = props;

	const [errors, setErrors] = useState({} as Errors);
	// disable eslint warning as we know only want the original email and don't listen to changes
	// eslint-disable-next-line
	const originalEmail = React.useMemo(() => model.user.email, []);

	const onNext = async () => {
		await validateAccountDetailsStep(model, errors, setErrors);
		if (originalEmail !== model.user.email) {
			runInAction(() => {
				model.continueEmailSent = false;
			});
		}
		if (Object.keys(errors).length === 0) {
			changePage();
		}
	};

	return (
		<div className="step-wrap">
			<div className="step-contents registration-step account-details-step align-one-third">
				<div className="alignment-box" ref={props.alignmentBoxRef}>
					<h5 className="step-header">Register your details</h5>
					<AccountFields
						user={model.user}
						errors={errors}
						setErrors={setErrors}
						readonly={false}
						isNewUser
						firstUser
					/>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="right"
					colors={Colors.Primary}
					display={Display.Solid}
					disabled={Object.keys(errors).length > 0}
					onClick={onNext}
				>
					Next
				</Button>
			</div>
		</div>
	);
});

export default AccountDetailsStep;
