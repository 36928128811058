import { store } from '../Models/Store';
import * as Pages from './Pages';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as React from 'react';
import PageAccessDenied from './Pages/PageAccessDenied';
import PageNotFound from './Pages/PageNotFound';
import { RouteComponentProps } from 'react-router';

export interface FrontendMonitorProps {
	path: string,
}

// Monitor routes should not be accessed if the user cannot access Monitor
export const FrontendMonitor = (props: FrontendMonitorProps) => {
	const { path } = props;
	document.title = 'Access Monitor';

	if (!store.appSettings.monitorEnabled) {
		return (
			<Switch>
				<Route path="/monitor" component={Pages.ProductMonitorComingSoon} />
			</Switch>
		);
	}
	if (!store.canAccessIntel) {
		return (
			<Switch>
				<Route path="/monitor" component={PageAccessDenied} />
			</Switch>
		);
	}

	const hasIntelCustomerOverview = store.userPermissions.intelCustomerOverview === true;
	const canUploadAtb = store.userPermissions.intelUploadAtb === true;
	const pages: {
		path: string,
		component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined,
		isAccessible?: boolean,
	}[] = [
		{
			path: '/monitor/customers/headings',
			component: Pages.EditCustomerHeadingsPage,
			isAccessible: store.userPermissions.commonManageOrganisations !== 'NONE',
		},
		{
			path: '/monitor/customers/add',
			component: Pages.AddCustomerPage,
			isAccessible: hasIntelCustomerOverview,
		},
		{
			path: '/monitor/customers/:customerId/registration/:registrationId',
			component: Pages.PpsrRegistrationPage,
			isAccessible: hasIntelCustomerOverview && store.userPermissions
				.intelViewCustomerPpsrRegistrations === true && store.canAccessPPSR,
		},
		{
			path: '/monitor/customers/:customerId',
			component: Pages.CustomerPage,
			isAccessible: hasIntelCustomerOverview,
		},
		{
			path: '/monitor/customers',
			component: Pages.CustomerListPage,
			isAccessible: true,
		},
		{
			path: '/monitor/upload/:type(agedtrialbalance|customers)/:fileId?',
			component: Pages.UploadAgedTrialBalancePage,
			isAccessible: canUploadAtb,
		},
		{
			path: '/monitor/dashboard',
			component: Pages.MonitorDashBoardPage,
			isAccessible: true,
		},
		{
			path: '/monitor/settings',
			component: Pages.MonitorSettingsPage,
			isAccessible: store.userType === 'SUPER_USER',
		},
	];

	const getAccessibleRoutes = () => {
		return pages
			.filter(page => page.isAccessible)
			.map(page => <Route key={page.path} path={page.path} component={page.component} />);
	};

	return (
		<Switch>
			<Redirect exact from="/monitor" to="/monitor/dashboard" />
			{
				getAccessibleRoutes()
			}
			<Route component={PageNotFound} />
		</Switch>
	);
};