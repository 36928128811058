import * as React from 'react';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import ProductSummary from 'Views/Components/Intel/Customer/ProductSummary';
import If from 'Views/Components/If/If';
import { RegistrationSummary } from 'Views/Components/Intel/Customer/PpsrRegistrationTypes';
import classNames from 'classnames';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import useStore from 'Hooks/useStore';

interface BusinessEntityPpsrSummary {
	ppsrSummary?: RegistrationSummary,
	errorMessage: string,
	errors: {
		[key: string]: string,
	},
}

interface ApproveSummary {
	notSubmitted: number,
	referred: number,
	pending: number,
	declined: number,
	approved: number,
	notStarted: number,
}

interface BusinessEntityApproveSummary {
	approveSummary?: ApproveSummary,
	errorMessage: string,
	errors: {
		[key: string]: string,
	},
}

const DashboardBrownfieldProducts = (() => {
	const store = useStore();

	const ppsrResponse = useAsync<RegistrationSummary>(async () => {
		const emptySummary = {
			expireNextThreeMonths: 0,
			expireNextSixMonths: 0,
			expireNextYear: 0,
			current: 0,
		} as RegistrationSummary;
		if (store.getUser?.organisation?.ppsrEnabled !== true) {
			return emptySummary;
		}
		try {
			const { data } = await axios.get(`${SERVER_URL}/api/entity/CustomerEntity/business-entities/ppsr-summary`);
			const result = data as BusinessEntityPpsrSummary;
			if (result.errorMessage != null || result.errors != null) {
				return emptySummary;
			}
			return result.ppsrSummary ?? emptySummary;
		} catch (e) {
			return emptySummary;
		}
	}, [store], { suppressSubsequentLoadingState: true });

	const approveResponse = useAsync<ApproveSummary>(async () => {
		const emptySummary = {
			notSubmitted: 0,
			referred: 0,
			pending: 0,
			declined: 0,
			approved: 0,
			notStarted: 0,
		} as ApproveSummary;
		if (store.getUser?.organisation?.approveEnabled !== true) {
			return emptySummary;
		}
		try {
			const { data } = await axios.get(
				`${SERVER_URL}/api/entity/CustomerEntity/business-entities/approve-summary`,
			);
			const result = data as BusinessEntityApproveSummary;
			if (result.errorMessage != null || result.errors != null) {
				return emptySummary;
			}
			return result.approveSummary ?? emptySummary;
		} catch (e) {
			return emptySummary;
		}
	}, [store], { suppressSubsequentLoadingState: true });

	const renderPpsrSummary = () => {
		const summaryContent = (
			<>
				<div className="product-title">
					<h6>PPSR Registrations</h6>
					<If condition={store.canAccessPPSR}>
						<div className="link-container">
							<span
								className="btn btn--text btn--primary icon-right icon-material-north-east"
							>
								Go to PPSR
							</span>
						</div>
					</If>
				</div>
				<div className="summaries">
					<ProductSummary
						count={!!ppsrResponse.data ? ppsrResponse.data?.expireNextThreeMonths ?? 0 : '-'}
						type="threeMonths"
						product="ppsr"
					/>
					<ProductSummary
						count={!!ppsrResponse.data
							? (
								(ppsrResponse.data?.expireNextSixMonths ?? 0)
								- (ppsrResponse.data?.expireNextThreeMonths ?? 0)
							)
							: '-'}
						type="sixMonths"
						product="ppsr"
					/>
					<ProductSummary
						count={!!ppsrResponse.data
							? (
								(ppsrResponse.data?.expireNextYear ?? 0)
								- (ppsrResponse.data?.expireNextSixMonths ?? 0)
							)
							: '-'}
						type="year"
						product="ppsr"
					/>
					<ProductSummary
						count={!!ppsrResponse.data ? ppsrResponse.data?.current ?? 0 : '-'}
						type="current"
						product="ppsr"
					/>
				</div>
			</>
		);
		return (
			<>
				<If condition={store.getUser?.organisation?.ppsrEnabled === true}>
					<If condition={store.canAccessPPSR}>
						<a
							className="dashboard-ppsr rounded-box"
							href={`${store.appSettings.ppsrBaseUrl}/registration/home.php`}
							target="_blank"
							rel="noreferrer"
						>
							{summaryContent}
						</a>
					</If>
					<If condition={!store.canAccessPPSR}>
						<div
							className="dashboard-ppsr rounded-box"
						>
							{summaryContent}
						</div>
					</If>
				</If>
				<If condition={!store.getUser?.organisation?.ppsrEnabled}>
					<div className="dashboard-ppsr rounded-box no-access">
						<div className={classNames('product-box-wrap', 'ppsr', 'disabled')}>
							<a
								className="product-box blue"
								href={store.appSettings.ppsrMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-box-logo ppsr"
									role="img"
									aria-label="Access PPSR"
								/>
							</a>
						</div>
						<div className="no-access-marketing-spiel">
							<p><b>Need to register on PPSR?</b></p>
							<If condition={store.userPermissions.commonManageOrganisations !== 'NONE'}>
								<p>You can upgrade your account here and setup only takes 2 minutes.</p>
								<Button
									display={Display.Solid}
									colors={Colors.Primary}
									onClick={() => {
										store.routerHistory.push('/hub/organisation/products');
									}}
								>
									PPSR Product Setup
								</Button>
							</If>
							<If condition={store.userPermissions.commonManageOrganisations === 'NONE'}>
								<p>
									Get in contact with your organisation manager
									to upgrade your account and gain access.
								</p>
								<p>Setup only takes 2 minutes.</p>
								<a
									className="marketing-url"
									target="_blank"
									rel="noreferrer"
									href={store.appSettings.ppsrMarketingUrl}
								>
									More Information
								</a>
							</If>
						</div>
					</div>
				</If>
			</>
		);
	};

	const renderApproveSummary = () => {
		const summaryContent = (
			<>
				<div className="product-title">
					<h6>Approve Applications</h6>
					<If condition={store.canAccessApprove}>
						<div className="link-container">
							<span
								className="btn btn--text btn--primary icon-right icon-material-north-east"
							>
								Go to Approve
							</span>
						</div>
					</If>
				</div>
				<div className="summaries">
					<ProductSummary
						count={!!approveResponse.data ? approveResponse.data?.notStarted ?? 0 : '-'}
						type="notStarted"
						product="approve"
					/>
					<ProductSummary
						count={!!approveResponse.data ? approveResponse.data?.notSubmitted ?? 0 : '-'}
						type="notSubmitted"
						product="approve"
					/>
					<ProductSummary
						count={!!approveResponse.data ? approveResponse.data?.pending ?? 0 : '-'}
						type="pending"
						product="approve"
					/>
					<ProductSummary
						count={!!approveResponse.data ? approveResponse.data?.referred ?? 0 : '-'}
						type="referred"
						product="approve"
					/>
				</div>
			</>
		);

		return (
			<>
				<If condition={store.getUser?.organisation?.approveEnabled === true}>
					<If condition={store.canAccessApprove}>
						<a
							className="dashboard-approve rounded-box"
							href={`${store.appSettings.approveBaseUrl}/index.php?page=Applications.php`}
							target="_blank"
							rel="noreferrer"
						>
							{summaryContent}
						</a>
					</If>
					<If condition={!store.canAccessApprove}>
						<div
							className="dashboard-approve rounded-box no-hover"
						>
							{summaryContent}
						</div>
					</If>
				</If>
				<If condition={!store.getUser?.organisation?.approveEnabled}>
					<div className="dashboard-approve rounded-box no-access">
						<div className={classNames('product-box-wrap', 'approve', 'disabled')}>
							<a
								className="product-box light-blue"
								href={store.appSettings.approveMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-box-logo approve"
									role="img"
									aria-label="Access Approve"
								/>
							</a>
						</div>
						<div className="no-access-marketing-spiel">
							<p><b>Want an Online Trade Application for your business?</b></p>
							<If condition={store.userPermissions.commonManageOrganisations !== 'NONE'}>
								<p>You can upgrade your account here and set up only takes 4 minutes.</p>
								<Button
									display={Display.Solid}
									colors={Colors.Primary}
									onClick={() => {
										store.routerHistory.push('/hub/organisation/products');
									}}
								>
									Approve Product Setup
								</Button>
							</If>
							<If condition={store.userPermissions.commonManageOrganisations === 'NONE'}>
								<p>
									Get in contact with your organisation manager
									to upgrade your account and gain access.
								</p>
								<p>Setup only takes 4 minutes.</p>
								<a
									className="marketing-url"
									target="_blank"
									rel="noreferrer"
									href={store.appSettings.approveMarketingUrl}
								>
									More Information
								</a>
							</If>
						</div>
					</div>
				</If>
			</>
		);
	};

	return (
		<div className="dashboard-brownfield-products">
			{renderApproveSummary()}
			{renderPpsrSummary()}
		</div>
	);
});

export default DashboardBrownfieldProducts;
