import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router-dom';
import { store } from 'Models/Store';
import React from 'react';
import ProductSwitcher from 'Views/Components/Navigation/ProductSwitcher';

export default function MonitorNavLinks(pageProps: RouteComponentProps): ILink[][] {
	return [
		[
			{
				label: 'Product Switcher',
				customComponent: (
					<ProductSwitcher productClass="monitor" />
				),
				path: '/',
				icon: 'home',
				iconPos: 'icon-left',
			},
		],
		[
			{
				label: 'Dashboard',
				path: '/monitor/dashboard',
			},
			{
				label: 'Customers',
				path: '/monitor/customers',
			},
			{
				label: 'Settings',
				path: '/monitor/settings',
				shouldDisplay: () => store.userType === 'SUPER_USER',
			},
			{
				label: 'Upload ATB data',
				path: '/monitor/upload/agedtrialbalance',
				shouldDisplay: () => store.userPermissions.intelUploadAtb === true,
			},
			{
				label: 'Upload Customers',
				path: '/monitor/upload/customers',
				shouldDisplay: () => store.userPermissions.intelUploadAtb === true,
			},
		],
		[
			{
				label: 'Login',
				path: '/login',
				icon: 'login',
				iconPos: 'icon-right',
				shouldDisplay: () => !store.loggedIn,
				className: 'login-logout-btn',
			},
			{
				label: 'Logout',
				path: '/logout',
				icon: 'logout',
				iconPos: 'icon-right',
				shouldDisplay: () => store.loggedIn,
				className: 'login-logout-btn',
			},
		],
	];
}
