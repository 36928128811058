import { AddressEntity, BusinessEntity } from 'Models/Entities';
import { Button, Display } from 'Views/Components/Button/Button';
import { observer, useLocalStore } from 'mobx-react';

import BusinessEntityFields from './BusinessEntityFields';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import Modal from 'Views/Components/Modal/Modal';
import React from 'react';
import { runInAction } from 'mobx';

export type AddBusinessDetailsModalProps = {
	businessEntity: BusinessEntity,
	organisationEmail?: string,
	isPrimaryBusinessEntity: boolean,
	onClose: () => void,
	onSubmit: () => Promise<void>,
}

const AddBusinessDetailsModal: React.FunctionComponent<AddBusinessDetailsModalProps> = observer(props => {
	const {
		businessEntity, organisationEmail, isPrimaryBusinessEntity, onClose, onSubmit,
	} = props;

	// Create a duplicate of the business entity, so that we can edit it while still being able to cancel and not save anything
	// This component assumes that nothing else will edit the entity in the background. (Since this should be covering everything)
	const privateBusinessEntity = useLocalStore(() => {
		const internalBusinessEntity = new BusinessEntity(businessEntity);
		internalBusinessEntity.address = new AddressEntity(businessEntity.address);
		return internalBusinessEntity;
	});

	const submit = async () => {
		await privateBusinessEntity.validateDetailsFields();

		if (Object.keys(privateBusinessEntity.errors).length === 0) {
			// Save all updated fields back to original business entity
			runInAction(() => {
				businessEntity.assignAttributes(privateBusinessEntity);
				businessEntity.address = new AddressEntity(privateBusinessEntity.address);
			});
			await onSubmit();
		}
	};

	return (
		<Modal
			isOpen
			label="Add business entity"
			onRequestClose={onClose}
			className="access-modal"
		>
			<h4>Add business entity</h4>
			<BusinessEntityFields
				isPrimary={isPrimaryBusinessEntity}
				businessEntity={privateBusinessEntity}
				originalBusinessEntity={privateBusinessEntity}
				organisationEmail={organisationEmail}
				addNew
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					onPress={submit}
					display={Display.Solid}
				>
					Add
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});
export default AddBusinessDetailsModal;
