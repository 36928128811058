import * as React from 'react';
import { observer } from 'mobx-react';
import OrganisationFields from 'Views/Components/Organisation/OrganisationFields';
import { action } from 'mobx';
import { BusinessEntity } from 'Models/Entities';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { useState } from 'react';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';

const BusinessEntityDetailsStep = observer((props: WizardComponentProps) => {
	const {
		model, changePage,
	} = props;

	const [nextDisabled, setNextDisabled] = useState(false);

	const errorsExist = () => {
		return model.businessEntitys.some((businessEntity: BusinessEntity) => {
			return Object.keys(businessEntity.errors).length > 0;
		});
	};

	const onNext = async () => {
		const validationPromises = [];
		for (const businessEntity of model.businessEntitys) {
			validationPromises.push(businessEntity.validateDetailsFields());
		}
		await Promise.all(validationPromises);

		if (!errorsExist()) {
			changePage();
		}
	};

	const onAdd = action(() => {
		model.businessEntitys.push(new BusinessEntity());
	});

	const renderBusinessEntitys = () => {
		const businessEntitys = [];
		for (let i = 1; i < model.businessEntitys.length; i++) {
			businessEntitys.push(
				<div id={`businessEntitys[${i}]`} key={`businessEntitys[${i}]`}>
					<OrganisationFields
						primaryBusinessEntity={model.businessEntitys[0]}
						businessEntity={model.businessEntitys[i]}
						type="nonPrimaryBusinessEntity"
					/>
					<div className="center-100 remove-entity">
						<Button
							onClick={action(() => {
								model.businessEntitys.splice(i);
							})}
							display={Display.Text}
							colors={Colors.Primary}
						>
							Remove Entity
						</Button>
					</div>
					<hr className="business-entity-sep" />
				</div>,
			);
		}

		return businessEntitys;
	};

	return (
		<div className="step-wrap">
			<div className="step-contents registration-step business-entity-details-step align-one-third">
				<div className="alignment-box">
					<h5 className="step-header">
						Are there any other trading entities within your organisation?
					</h5>
					<div>
						{ renderBusinessEntitys() }
					</div>
					<div className="center-100">
						<Button
							className="add-business-entity-button icon-left icon-plus"
							colors={Colors.Alternate}
							display={Display.Outline}
							onClick={() => onAdd()}
						>
							Add Business Entity
						</Button>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<ButtonAsyncState
					className="right"
					colors={Colors.Primary}
					display={Display.Solid}
					readonly={errorsExist()}
					onPress={onNext}
					waitingText="Validating..."
				>
					Next
				</ButtonAsyncState>
			</div>
		</div>
	);
});

export default BusinessEntityDetailsStep;
