import * as React from 'react';
import AccessGroupLogo from 'Assets/Images/Access_logo.svg';
import { WizardStepInformation } from 'Views/Components/Wizard/Wizard';

export interface WizardTopBarProps {
	currentStep: WizardStepInformation,
}

const WizardTopBar = (props: WizardTopBarProps) => {
	const { currentStep } = props;
	const img = currentStep.topBarLogo || AccessGroupLogo;
	const title = currentStep.topBarTitle;

	return (
		<div className="wizard-top-bar-wrap">
			<div className={`wizard-top-bar ${currentStep.topBarType ?? 'access-group'}`}>
				<img src={img} alt="Product Logo" />
				{!title ? null : <h5>{title}</h5>}
			</div>
			<div className="preload">
				<img src={AccessGroupLogo} alt="" />
			</div>
		</div>
	);
};
export default WizardTopBar;
